import { Formik } from 'formik';
import React, { VoidFunctionComponent } from 'react';
import { logout } from '../utilities/request/auth';
import { Routes, linkTo } from '../utilities/routes';
import Button from './button/Button';

const LogoutForm: VoidFunctionComponent = () => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        await logout();
        // hard reload so entire app is re-loaded and tokens reset
        window.location.href = linkTo(Routes.home);
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Button
              variant="normal"
              color="neutral"
              mode="outline"
              type="submit"
              s="smaller"
            >
              Log out
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default LogoutForm;
