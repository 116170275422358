import Heading from '@components/heading/Heading';
import { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  actionsLeft: ReactNode;
  actionsRight: ReactNode;
  children: ReactNode;
};

export const Panel = ({
  children,
  title,
  actionsLeft,
  actionsRight,
}: Props) => {
  return (
    <div className="h-full flex py-1 grow w-panelWithMargin relative items-stretch">
      <div className="bg-primary rounded-lg items-stretch shadow-panel flex flex-col grow absolute inset-y-2 w-panel border-l border-secondary text-primary">
        <div className="flex items-center justify-between min-h-[56px] px-3 py-2 border-b border-light">
          <Heading
            level={2}
            headingStyle="heading1"
            className="flex items-center gap-3 truncate grow"
          >
            {actionsLeft} <span className="truncate grow">{title}</span>
          </Heading>
          <div className="flex gap-2 ml-2">{actionsRight}</div>
        </div>
        {children}
      </div>
    </div>
  );
};
