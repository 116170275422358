'use client';

import { useLoggedInAs } from '@/hooks/useLoggedInAs';
import { logoutAs } from '@/utilities/request/auth';
import { Button } from '@packfleet/ui';

export const LoggedInAs = () => {
  const { loggedInAs, impersonatedBy } = useLoggedInAs();

  if (!impersonatedBy || !loggedInAs) return null;

  return (
    <>
      <div className="fixed bottom-0 w-full bg-warning p-2 text-center text-primaryInverted">
        <div className="flex items-center justify-center">
          <div>Youʼre logged in as {loggedInAs.email}.</div>
          <div>
            <form
              method="POST"
              onSubmit={async (e) => {
                e.preventDefault();
                await logoutAs();
                window.location.reload();
              }}
            >
              <Button
                type="submit"
                className="ml-2"
                s="smaller"
                color="neutral"
                mode="secondary"
              >
                Stop running as
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="fixed bg-warning z-20 top-0 bottom-0 w-4 left-0" />
      <div className="fixed bg-warning z-20 top-0 bottom-0 w-4 right-0" />
      <div className="fixed bg-warning z-20 left-0 right-0 h-4 top-0" />
    </>
  );
};
