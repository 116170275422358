import { ShipmentOnMoreThanOneRouteFragment } from 'generated/graphql';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { ShipmentNotes } from './utils/ShipmentNotes';
import { ShipmentStatusHistory } from './utils/ShipmentStatusHistory';

type Props = {
  task: ShipmentOnMoreThanOneRouteFragment;
  taskId: string;
};

export const ShipmentOnMoreThanOneRoute = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentDetails shipmentId={task.shipmentId} />

      <ShipmentNotes shipmentId={task.shipmentId} />

      <SectionHeading title="Context" />
      <ShipmentStatusHistory shipmentId={task.shipmentId} />

      {task.routeActivities.map((routeActivity, index) => (
        <RouteActivity
          key={routeActivity.routeActivityId}
          routeActivity={routeActivity}
          title={`Route ${index + 1}`}
        />
      ))}
    </div>
  );
};
