export function pluralize(count: number, singular: string, plural: string) {
  if (count === 1) return singular;
  return plural;
}

export function initialize(str: string | null | undefined) {
  if (!str) {
    return '';
  }

  const names = str
    .replaceAll(new RegExp('[()]', 'g'), '')
    .split(' ')
    .slice(0, 2);
  return names.reduce((acc, n) => acc + n.charAt(0), '');
}

export function initializeSurname(str: string | null | undefined): string {
  if (!str) {
    return '';
  }

  return str
    .split(/[ -]/g)
    .map((name, index) => (index === 0 ? `${name} ` : name.charAt(0)))
    .join('')
    .trimEnd();
}
