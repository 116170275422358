import { IncomingCall } from '@components/incoming-call/IncomingCall';
import { TasksDetail } from '@components/tasks/sidebar/TasksDetails';
import { TasksSidebar } from '@components/tasks/sidebar/TasksSidebar';
import { Inter } from '@next/font/google';
import cs from 'classnames';
import React, { ReactNode } from 'react';
import { BreadcrumbItem } from '../breadcrumbs/Breadcrumbs';
import HeadTag, { Meta } from '../head-tag/HeadTag';
import NavbarLight from '../navbar-light/NavbarLight';
import Navbar from '../navbar/Navbar';
import { ReusableComponentProps } from '../types';
import { LoggedInAs } from './LoggedInAs';

const inter = Inter({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  display: 'swap',
});

export type Props = ReusableComponentProps & {
  title?: string;
  customHeader?: React.ReactNode;
  fixedHeight?: boolean;
  sidebar?: React.ReactElement;
  meta?: Meta;
  hideNavbar?: boolean;
  navbarLight?: boolean;
  breadcrumbs?: BreadcrumbItem[];
  children?: ReactNode;
  bg?: 'primary' | 'secondary';
};

const Page = ({
  title,
  children,
  className,
  fixedHeight,
  style,
  sidebar,
  breadcrumbs,
  hideNavbar,
  navbarLight,
  meta = {},
  bg = 'primary',
}: Props) => {
  return (
    <>
      <HeadTag title={title} meta={meta}>
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
      </HeadTag>

      {navbarLight && <NavbarLight />}

      {hideNavbar || navbarLight ? null : (
        <Navbar
          breadcrumbs={breadcrumbs}
          environment={process.env.NEXT_PUBLIC_ENVIRONMENT}
        />
      )}
      {sidebar ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            position: 'fixed',
            zIndex: 1,
          }}
          className={cs('md:max-w-sidebar', {
            'pt-16': !hideNavbar && !navbarLight,
            'left-0 top-16 border-l border-primary md:top-0 md:left-16':
              navbarLight,
          })}
        >
          {sidebar}
        </div>
      ) : null}
      <main
        className={cs('flex', inter.className, className, {
          'bg-primary': bg === 'primary',
          'bg-secondary': bg !== 'primary',
          'pt-16': !hideNavbar && !navbarLight,
          'pl-0 pt-16 md:pt-0 md:pl-[64px]': navbarLight,
          'hidden md:flex': sidebar,
        })}
        style={{
          minHeight: '100%',
          height: fixedHeight ? '100%' : undefined,
          paddingLeft: sidebar ? 480 : undefined,
          minWidth: sidebar ? 421 : 1,
          ...style,
        }}
      >
        <TasksSidebar />
        <TasksDetail />
        <IncomingCall />
        {children}
      </main>
      <div id="portal-target" className="pointer-events-auto" />
      <LoggedInAs />
    </>
  );
};

export default Page;
