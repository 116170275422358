import React from 'react';

export type Address = {
  line1: string;
  line2?: string | null;
  line3?: string | null;
  city: string;
  countryCode: string;
  postCode: string;
  coordinatesOverridden?: boolean | null;
  geocodedAddress?: GeocodedAddress | null;
};

export type GeocodedAddress = {
  streetAddress: string;
  postCode: string;
  city: string;
  country: string;
  latitude?: number | null;
  longitude?: number | null;
  partialMatch?: boolean;
  largeUserPostcode?: boolean;
  smallOrganization?: boolean;
  isBusinessAddress?: boolean;
  poBoxNumber?: string | null;

  // Matching IDs
  googlePlaceId?: string | null;
  udprn?: string | null; // https://ideal-postcodes.co.uk/guides/udprn
  umprn?: string | null; // https://ideal-postcodes.co.uk/guides/multiple-residence
};

export type Location = {
  name?: string | null;
  companyName?: string | null;
  address: Address;
};

export type Props = {
  value: Location;
};

const DetailLocation = ({ value }: Props) => {
  return (
    <div className="text-left">
      <p>{value.name}</p>
      <p>{value.companyName}</p>
      <p>{value.address.line1}</p>
      <p>{value.address.line2}</p>
      <p>{value.address.line3}</p>
      <p>{value.address.city}</p>
      <p>{value.address.postCode}</p>
      {value.address.geocodedAddress ? (
        <div className="mt-2 whitespace-pre-wrap text-sm text-secondary">
          <p>
            <strong>Geocoding</strong>
          </p>
          <p>
            {value.address.coordinatesOverridden
              ? 'Coordinates Overridden'
              : value.address.geocodedAddress.streetAddress}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default DetailLocation;
