import {
  getRefetchQueries,
  getRefetchRouteQueries,
} from '@components/tasks/utils';
import {
  CollectionCancelledFragment,
  useRemoveRouteActivityFromVehicleMutation,
} from 'generated/graphql';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { CollectionDetails } from './utils/CollectionDetails';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: CollectionCancelledFragment;
  taskId: string;
};

export const CollectionCancelled = ({ task, taskId }: Props) => {
  const [removeRouteActivity] = useRemoveRouteActivityFromVehicleMutation();

  return (
    <div className="flex flex-col gap-4 py-3">
      <CollectionDetails
        collectionId={task.collectionId}
        organizationId={task.organizationId}
      />

      <SectionHeading title="Context" />
      <RouteActivity title="Route" routeActivity={task.routeActivity} />

      <SectionHeading title="Actions" />
      <TaskActionButton
        text="Remove from route"
        role="destructive"
        taskIdToCompleteOnClick={taskId}
        onClick={async () =>
          extractGraphQLErrorIfExists(
            removeRouteActivity({
              variables: {
                input: {
                  activityId: task.routeActivity.routeActivityId.toString(),
                  optimizedRouteVehicleId:
                    task.routeActivity.vehicleId.toString(),
                  date: task.routeActivity.date,
                },
              },
              refetchQueries: [
                ...getRefetchQueries(taskId),
                ...getRefetchRouteQueries([task.routeActivity.vehicleId]),
              ],
            }),
            'removeRouteActivityFromVehicle',
          )
        }
      />
    </div>
  );
};
