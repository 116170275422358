import Skeleton from '@components/skeleton/Skeleton';
import { useReadCollectionQuery } from 'generated/graphql';
import Link from 'next/link';
import { formatAddress } from 'utilities/address';
import { Routes, linkTo } from 'utilities/routes';
import { formatStartEndTime } from 'utilities/time';

type Props = {
  collectionId: string;
  organizationId: string;
};
export const CollectionDetails = ({ collectionId, organizationId }: Props) => {
  const { data, loading, error } = useReadCollectionQuery({
    variables: { input: { id: collectionId } },
  });
  const collection = data?.readCollection?.collection;

  const summaryLine =
    collection?.location.name || collection?.location.address.line1;
  return (
    <div className="px-3">
      <Link
        className="border border-secondary rounded p-4 block"
        href={linkTo(Routes.merchantCollection, {
          id: organizationId,
          collectionId: collectionId,
        })}
      >
        {loading ? <Skeleton height={75} /> : null}
        {error?.message ? (
          <p className="text-warning">{error.message}</p>
        ) : null}
        {collection ? (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-secondary">
              {formatStartEndTime(collection.startTime, collection.endTime)}
            </p>
            <p className="flex gap-2 items-baseline">
              <span className="text-lg">
                {collection.organization.name}
                {summaryLine ? ` (${summaryLine})` : null}
              </span>
            </p>
            <p className="text-sm text-secondary">
              {formatAddress(collection.location.address)}
            </p>
          </div>
        ) : null}
      </Link>
    </div>
  );
};
