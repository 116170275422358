import Skeleton from '@components/skeleton/Skeleton';
import {
  getPodFriendlyName,
  getTypeFriendlyName,
} from '@components/tasks/AddressFeedbackFromDriver/utils';
import classNames from 'classnames';
import {
  AddressFeedbackFromDriverFragment,
  AddressFeedbackType,
  ProofOfCompletionStatus,
  ProofOfDeliveryFragment,
} from 'generated/graphql';
import Link from 'next/link';
import { Routes, linkTo } from 'utilities/routes';

type Props = {
  type: AddressFeedbackType;
  loading: boolean;
  driver:
    | AddressFeedbackFromDriverFragment['routeActivity']['driver']
    | null
    | undefined;
  notes: string | null | undefined;
  proofOfDelivery: ProofOfDeliveryFragment | null | undefined;
};

const SUCCESSFUL_PROOF_STATUSES = new Set([
  ProofOfCompletionStatus.Collected,
  ProofOfCompletionStatus.DeliveredToRecipient,
  ProofOfCompletionStatus.LeftInSafePlace,
  ProofOfCompletionStatus.LeftWithNeighbour,
  ProofOfCompletionStatus.LeftWithReception,
]);

export const AddressFeedbackDetails = ({
  type,
  loading,
  driver,
  notes,
  proofOfDelivery,
}: Props) => {
  const successfulProof = proofOfDelivery?.status
    ? SUCCESSFUL_PROOF_STATUSES.has(proofOfDelivery.status)
    : false;

  return (
    <div className="px-3">
      <div className="border border-secondary rounded p-4 block">
        {loading ? <Skeleton height={75} /> : null}
        <div className="flex flex-col gap-1">
          <p className="flex gap-2 items-baseline">
            <span className="text-lg">{getTypeFriendlyName(type)}</span>
          </p>
          <p className="text-sm">{/* Address */}</p>
          <p className="text-secondary text-sm">
            {driver ? (
              <Link
                href={linkTo(Routes.driver, { id: driver.id })}
                className="text-link"
              >
                {driver.name}
              </Link>
            ) : null}
            {notes ? ' said:' : null}
          </p>
          {notes ? (
            <p className="bg-info/20 p-4 rounded-sm italic text-sm my-2">
              {notes}
            </p>
          ) : null}
          {proofOfDelivery ? (
            <>
              <p className="text-secondary text-sm">
                {getPodFriendlyName(proofOfDelivery.status)}
              </p>
              {proofOfDelivery.notes ? (
                <p
                  className={classNames('p-4 rounded-sm italic text-sm my-2', {
                    'bg-success/20': successfulProof,
                    'bg-danger/20': !successfulProof,
                  })}
                >
                  {proofOfDelivery.notes}
                </p>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
