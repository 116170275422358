import React, { useCallback, useRef, useState } from 'react';

export function useDebounced<Args extends any[]>(
  func: (...param: Args) => void,
  timeoutMs: number,
  deps: React.DependencyList,
): [(...param: Args) => void, { loading: boolean }] {
  const timeout = useRef<number | null>(null);
  const [loading, setLoading] = useState(false);
  const cb = useCallback(
    (...val: Args) => {
      setLoading(true);
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(async () => {
        await func(...val);
        setLoading(false);
      }, timeoutMs);
    },
    [deps],
  );

  return [cb, { loading }];
}
