import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { Routes, linkTo } from '@/utilities/routes';
import { TaskActionButton } from '@components/tasks/sidebar/task-content/utils/TaskActionButton';
import {
  RouteActivitiesNeedAttentionFragment,
  RouteActivitiesNeedAttentionReason,
  RouteActivityDeletedReason,
  useRemoveRouteActivitiesFromVehicleMutation,
} from 'generated/graphql';
import groupBy from 'lodash.groupby';
import Link from 'next/link';
import { useMemo } from 'react';

type Props = {
  task: RouteActivitiesNeedAttentionFragment;
  taskId: string;
};

const formatReason = (reason: RouteActivitiesNeedAttentionReason): string => {
  switch (reason) {
    case RouteActivitiesNeedAttentionReason.DeliveriesRunningLate:
      return 'Deliveries running late';
    case RouteActivitiesNeedAttentionReason.CollectionsRunningLate:
      return 'Collections running late';
    case RouteActivitiesNeedAttentionReason.LateReturningNationwideCollections:
      return 'Nationwide packs are not due to return to the depot in time for the external carrier to collect them';
  }
};

export const RouteActivitiesNeedAttention = ({ task }: Props) => {
  const [removeActivities] = useRemoveRouteActivitiesFromVehicleMutation();
  const activtiesByVehicle = useMemo(
    () => groupBy(task.routeActivities, (a) => a.vehicleId.toString()),
    [task.routeActivities],
  );

  return (
    <div className="px-3 py-2">
      <p className="pb-2 text-sm text-primary">{formatReason(task.reason)}</p>
      {Object.entries(activtiesByVehicle).map(([vehicleId, activities]) => (
        <div key={vehicleId} className="mb-4">
          <p className="text-sm text-secondary">
            {activities[0].driver?.name || activities[0].routeDisplayName}
          </p>
          <ul>
            {activities.map((activity) => (
              <li key={activity.routeActivityId}>
                <Link
                  className="text-link"
                  href={linkTo(Routes.routeActiveDriverActivity, {
                    date: activity.date,
                    vehicleId: activity.vehicleId.toString(),
                    activityId: activity.routeActivityId.toString(),
                  })}
                >
                  {activity.displayTitle}
                </Link>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <TaskActionButton
              text="Remove from routes"
              role="destructive"
              dialog={{
                title: 'Are you sure?',
                description: `This will remove ${
                  activities.length
                } stops from ${
                  activities[0].driver?.name || activities[0].routeDisplayName
                }`,
              }}
              onClick={async () => {
                return extractGraphQLErrorIfExists(
                  removeActivities({
                    variables: {
                      input: {
                        optimizedRouteVehicleId: vehicleId,
                        activityIds: activities.map((a) =>
                          a.routeActivityId.toString(),
                        ),
                        reason: RouteActivityDeletedReason.EtaOutsideTimeWindow,
                      },
                    },
                  }),
                  'removeRouteActivitiesFromVehicle',
                );
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
