import React from 'react';
import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

export type Props = SkeletonProps;

const Skeleton = (props: Props) => {
  return <ReactSkeleton {...props} />;
};

export default Skeleton;
