import { Maybe } from '../generated/graphql';
import { truthy } from './truthy';

export type TimeType = {
  hour: number;
  minute: number;
  totalMinutes: number;
  valid: boolean;
};

export function parseTime(val?: string | null): TimeType {
  if (!val) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  const result = val.split(':');
  if (result.length != 2) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  if (!result.every((part) => part.length === 2)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  const hour = parseInt(result[0]);
  if (isNaN(hour)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }
  const minute = parseInt(result[1]);
  if (isNaN(minute)) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }

  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    return { hour: 0, minute: 0, totalMinutes: 0, valid: false };
  }

  return { hour, minute, totalMinutes: hour * 60 + minute, valid: true };
}

export function formatStartEndTime(start?: Maybe<string>, end?: Maybe<string>) {
  if (start && end) {
    return `${start} – ${end}`;
  } else if (start) {
    return `> ${start}`;
  } else if (end) {
    return `< ${end}`;
  }
  return '';
}

// Swap this for Temporal.Duration once it has
// better support https://caniuse.com/mdn-javascript_builtins_intl_durationformat_format
export function formatSeconds(
  totalSeconds: number,
  smallestUnit: 'minute' | 'second' = 'minute',
) {
  const absoluteSeconds = Math.abs(totalSeconds);
  if (absoluteSeconds === 0) {
    return '0s';
  }
  const days = Math.floor(absoluteSeconds / 3600 / 24);
  const hours = Math.floor(absoluteSeconds / 3600) % 24;
  const mins = Math.floor(absoluteSeconds / 60) % 60;
  const seconds = Math.round(absoluteSeconds) % 60;

  const filtered = [
    [days, 'd'],
    [hours, 'h'],
    [mins, 'm'],
    smallestUnit === 'second' ? [seconds, 's'] : null,
  ]
    .filter(truthy)
    .filter((t) => t[0]);

  if (filtered.length === 0) {
    return '0s';
  }

  return (
    (totalSeconds < 0 ? '-' : '') +
    filtered.map(([duration, unit]) => `${duration}${unit}`).join(' ')
  );
}
