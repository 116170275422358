import { PanelLevel } from '@components/panel/utils';
import { AnimatedPanel } from '../../panel/AnimatedPanel';
import { SidebarContent } from './SidebarContent';
import { useTasksContext } from './useTasksContext';

export const TasksSidebar = () => {
  const tasksContext = useTasksContext();
  const isOpen = tasksContext.state.sidebarOpen;

  return (
    <AnimatedPanel
      level={PanelLevel.TASK_SIDEBAR}
      isOpen={isOpen}
      isOffsetByDefault={false}
    >
      {() => <SidebarContent />}
    </AnimatedPanel>
  );
};
