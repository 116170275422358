import { getDateReason } from '@components/shipment/DeliveryDateReason';
import Skeleton from '@components/skeleton/Skeleton';
import {
  TZ_EUROPE_LONDON,
  formatFriendlyDateTime,
  formatFriendlyPlainDateShort,
  parseInstant,
  parsePlainDate,
} from '@packfleet/datetime';
import { useShipmentQuery } from 'generated/graphql';

type Props = {
  shipmentId: string;
};

export const ShipmentDateHistory = ({ shipmentId }: Props) => {
  const { data, loading, error } = useShipmentQuery({
    variables: { id: shipmentId },
  });
  const shipment = data?.shipment;

  const d = shipment?.delivery.dateHistory?.[0];

  return (
    <div className="px-3">
      {loading ? <Skeleton height={70} /> : null}
      {error?.message ? <p className="text-warning">{error.message}</p> : null}
      {shipment ? (
        <div className="flex flex-col gap-2">
          <div>
            <div className="text-sm text-secondary">
              {shipment.delivery.dateReason
                ? getDateReason(shipment.delivery.dateReason)
                : null}
            </div>
            <div>
              {d ? formatFriendlyPlainDateShort(parsePlainDate(d.date)) : null}{' '}
              -&gt;{' '}
              {formatFriendlyPlainDateShort(
                parsePlainDate(shipment.delivery.date),
              )}
            </div>
            {d ? (
              <div className="text-sm text-secondary">
                on{' '}
                {formatFriendlyDateTime(
                  parseInstant(d.createdAt).toZonedDateTimeISO(
                    TZ_EUROPE_LONDON,
                  ),
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
