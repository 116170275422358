import { CollectionsNotOnRouteFragment } from 'generated/graphql';
import { useEffect } from 'react';
import { formatAddress } from 'utilities/address';
import { Routes, linkTo } from 'utilities/routes';
import { formatStartEndTime } from 'utilities/time';
import { truthy } from 'utilities/truthy';
import { MapInfo, useTasksContext } from '../useTasksContext';
import { CollectionsDetails } from './utils/CollectionsDetails';
import { SectionHeading } from './utils/SectionHeading';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: CollectionsNotOnRouteFragment;
  taskId: string;
};

export const CollectionsNotOnRoute = ({ task }: Props) => {
  const { action } = useTasksContext();

  useEffect(() => {
    if (task) {
      action.displayTaskInfoOnMap(getMapMarkers(task));
    }
    return () => action.clearTaskInfoOnMap();
  }, [task]);

  return (
    <div className="flex flex-col gap-4 py-3">
      <CollectionsDetails collections={task.collections} />

      <SectionHeading title="Actions" />
      <TaskActionButton
        text="Add to routes"
        role="primary"
        href={linkTo(Routes.routeActive, { date: task.date })}
      />
    </div>
  );
};

export function getMapMarkers(task: CollectionsNotOnRouteFragment): MapInfo {
  return {
    markers: task.collections
      .map((collection) => {
        if (
          collection.address.latitude == null ||
          collection.address.longitude == null
        ) {
          return null;
        }

        const abreviatedName = collection.organization.name
          .split(' ')
          .map((s) => s.charAt(0))
          .join('');
        const timeWindow = formatStartEndTime(
          collection.startTime,
          collection.endTime,
        );

        return {
          id: collection.id,
          routeDate: task.date,
          title: `🏢 ${abreviatedName}${timeWindow ? ` (${timeWindow})` : ''}`,
          location: {
            lat: collection.address.latitude,
            lng: collection.address.longitude,
          },
          showPopupOnHover: false,
          // Copy it in the format that can be pasted in the search box
          copyText: formatAddress(collection.address),
          popupText: [
            [
              collection.organization.name,
              collection.location.name,
              formatAddress(collection.address),
            ]
              .filter(truthy)
              .join(', '),
          ],
        };
      })
      .filter(truthy),
  };
}
