import cs from 'classnames';
import React, {
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import ArrowBox from '../arrow-box/ArrowBox';
import Link from '../link/Link';

export type MenuItem =
  | {
      href?: undefined;
      title?: undefined;
      renderComponent: (opts: { activeHref?: string }) => React.ReactNode;
    }
  | {
      href: string;
      title: string;
      renderComponent?: undefined;
    };

export type Props = {
  show: boolean;
  attachToRef: RefObject<HTMLElement>;
  menuItems: MenuItem[];
  onClose: () => void;
  minWidth?: number;
  activeHref?: string;
};

const PopoverMenu: FunctionComponent<Props> = ({
  show,
  attachToRef,
  onClose,
  menuItems,
  minWidth,
  activeHref,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const initialWindowWidth = useRef<number>(window.innerWidth);

  const reposition = useCallback(() => {
    if (!show) return;
    if (attachToRef.current?.offsetParent === null) {
      onClose();
      return;
    }
    const refRect = attachToRef.current?.getBoundingClientRect();
    const containerRect = ref.current?.getBoundingClientRect();
    const node = ref.current;
    const arrow = arrowRef.current;
    if (!node || !containerRect || !refRect || !arrow) return;

    const top = refRect.bottom + window.scrollY;
    let left =
      refRect.left +
      refRect.width / 2 +
      window.scrollX -
      containerRect.width / 2;
    const right = left + containerRect.width;

    if (initialWindowWidth.current - right < 10) {
      left = initialWindowWidth.current - 10 - containerRect.width;
    } else if (left < 10) {
      left = 10;
    }

    arrow.style.left = `${refRect.left - left + refRect.width / 4}px`;
    node.style.top = `${top + 10}px`;
    node.style.left = `${left}px`;
  }, [show, attachToRef, onClose]);

  const resize = useCallback(() => {
    initialWindowWidth.current = window.innerWidth;
    reposition();
  }, [reposition]);

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined')
      return;
    reposition();
    const close = (e: MouseEvent) => {
      if (
        ref.current?.contains(e.target as Node) ||
        attachToRef.current?.contains(e.target as Node)
      )
        return;
      onClose();
    };
    window.addEventListener('resize', resize);
    document.addEventListener('mousedown', close);
    return () => {
      window.removeEventListener('resize', resize);
      document.removeEventListener('mousedown', close);
    };
  }, [show, attachToRef, onClose, reposition, resize]);

  useEffect(() => reposition(), [ref, attachToRef, reposition]);

  if (typeof window === 'undefined' || typeof document === 'undefined')
    return null;

  return ReactDOM.createPortal(
    <ArrowBox
      ref={ref}
      arrowRef={arrowRef}
      className="absolute z-20 rounded border border-primary bg-primary"
      style={{
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        minWidth,
        display: show ? '' : 'none',
      }}
    >
      {menuItems.map((menuItem, index) => {
        const className = cs('px-4 py-3 text-center w-full', {
          'border-t border-primary': index !== 0,
        });
        return (
          <div key={index}>
            {menuItem.renderComponent ? (
              <div className={className}>
                {menuItem.renderComponent({ activeHref })}
              </div>
            ) : activeHref == null || activeHref !== menuItem.href ? (
              <div className={className}>
                <Link unstyled className="hover:text-info" href={menuItem.href}>
                  {menuItem.title}
                </Link>
              </div>
            ) : (
              <div className={className}>
                <strong className="text-sm text-primary">
                  {menuItem.title}
                </strong>
              </div>
            )}
          </div>
        );
      })}
    </ArrowBox>,
    document.body,
  );
};

export default PopoverMenu;
