import classNames from 'classnames';
import useDebounce from 'hooks/useDebounce';
import { ReactNode, useState } from 'react';
import { BsChevronBarLeft, BsChevronBarRight } from 'react-icons/bs';
import { PanelLevel } from './utils';

export const ANIMATION_TIME = 250;

type Props = {
  isOpen: boolean;
  children: (translateButtons: ReactNode) => ReactNode;
  level: PanelLevel;
  allowOffset?: boolean;
  isOffsetByDefault: boolean;
};

export const AnimatedPanel = ({
  children,
  isOpen,
  level,
  allowOffset = true,
  isOffsetByDefault,
}: Props) => {
  const [isOffset, setIsOffset] = useState(isOffsetByDefault);
  const debouncedIsOpen = useDebounce(isOpen, ANIMATION_TIME);

  return (
    <div
      className={classNames(
        'h-screen transition-[width,margin] ease-in-out overflow-hidden shrink-0 inline-flex flex-col',
        {
          'w-panelWithMargin': isOpen,
          'w-0': !isOpen,
          '-ml-[312px]': isOffset && isOpen && allowOffset,
        },
      )}
      style={{ transitionDuration: `${ANIMATION_TIME}ms`, zIndex: level }}
    >
      {isOpen || debouncedIsOpen
        ? children(
            allowOffset ? (
              <button
                onClick={() => setIsOffset(!isOffset)}
                className="py-1 px-2 shadow rounded-lg border border-light w-[32px] h-[32px] shrink-0"
              >
                {isOffset ? (
                  <BsChevronBarRight size="md" />
                ) : (
                  <BsChevronBarLeft size="md" />
                )}
              </button>
            ) : null,
          )
        : null}
    </div>
  );
};
