import { Button } from '@components/button/Button';
import { IconButton } from '@packfleet/ui';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import cs from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { TbX } from 'react-icons/tb';
import styles from './Dialog.module.css';

type Props = {
  trigger: React.ReactNode;
  title: string;
  description?: string;
  children?: React.ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  onConfirm: () => Promise<{ error?: string } | undefined | void> | void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  loadingText?: string;
  confirmColor?: 'success' | 'info' | 'warning' | 'danger' | 'brand';
  disabled?: boolean;
  async?: boolean;
  initiallyOpen?: boolean;
};

export const Dialog = forwardRef(function Dialog(
  {
    title,
    trigger,
    description,
    children,
    onConfirm,
    disabled,
    onCancel,
    onOpenChange,
    loadingText,
    cancelText,
    async = true,
    confirmText = 'Done',
    confirmColor = 'success',
    initiallyOpen = false,
  }: Props,
  ref,
) {
  const [open, setOpen] = useState(initiallyOpen);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const _onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (!open && onCancel) {
        onCancel();
      }
      if (!open) {
        setError('');
      }
    },
    [onCancel],
  );

  useEffect(() => onOpenChange?.(open), [open, onOpenChange]);
  useEffect(() => () => onOpenChange?.(false), [onOpenChange]);

  const onClickConfirm = useCallback(async () => {
    if (async) {
      setLoading(true);
    }
    const result = await onConfirm();
    if (async) {
      setLoading(false);
    }
    if (result && result.error) {
      setError(result.error);
    } else {
      setOpen(false);
      setError('');
    }
  }, [onConfirm]);

  // Give the parent component the ability to open and close the dialog
  // this is useful when not being used in conjunction with a simple
  // trigger, but needs to be triggered by another means
  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  return (
    <DialogPrimitive.Root open={open} onOpenChange={_onOpenChange}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          className={cs(
            'fixed z-10 bg-primaryInverted/30',
            styles.dialogOverlay,
          )}
          style={{
            inset: 0,
          }}
        />
        <DialogPrimitive.Content
          className={cs('z-20 overflow-auto', styles.dialogContent)}
          style={{
            backgroundColor: 'white',
            borderRadius: 6,
            boxShadow:
              'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            maxWidth: '450px',
            maxHeight: '85vh',
            padding: 25,
          }}
        >
          <DialogPrimitive.Title className="mt-4 text-xl font-semibold">
            {title}
          </DialogPrimitive.Title>
          <DialogPrimitive.Description className="mt-2 text-secondary">
            {description}
          </DialogPrimitive.Description>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await onClickConfirm();
            }}
          >
            <div className="my-8">{children}</div>
            {error ? (
              <div className="my-4 rounded bg-dangerLight p-4 text-center font-medium text-danger">
                {error}
              </div>
            ) : null}
            <div className="flex justify-end gap-2">
              {cancelText ? (
                <DialogPrimitive.Close asChild>
                  <Button
                    aria-label="Cancel"
                    disabled={loading}
                    s="small"
                    color="neutral"
                    mode="outline"
                  >
                    {cancelText}
                  </Button>
                </DialogPrimitive.Close>
              ) : null}
              <Button
                type="submit"
                s="small"
                disabled={loading || disabled}
                loading={loading}
                loadingText={loadingText}
                color={confirmColor}
              >
                {confirmText}
              </Button>
            </div>
          </form>
          <DialogPrimitive.Close
            asChild
            className="fixed"
            style={{
              top: 8,
              right: 8,
            }}
          >
            <IconButton
              aria-label="Close"
              icon={TbX}
              className="text-2xl text-secondary"
            />
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
});

export default Dialog;
