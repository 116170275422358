import { Tooltip } from '@packfleet/ui';
import cs from 'classnames';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { IconType } from 'react-icons';
import { ComponentStylingOptions, ReusableComponentProps } from '../types';

export type BaseButtonProps<T> = React.HTMLProps<T> &
  ReusableComponentProps & {
    component?: React.ElementType | keyof JSX.IntrinsicElements;
    disabled?: boolean;
    componentProps?: React.ComponentProps<
      React.ElementType | keyof JSX.IntrinsicElements
    >;
    style?: CSSProperties;
    border?: boolean;
    icon: IconType;
    inverted?: boolean;
    iconClassName?: string;
    iconStyle?: CSSProperties;
  };

export function IconButton<T>({
  component: Component = 'button',
  className,
  disabled,
  style,
  border,
  icon: Icon,
  componentProps,
  iconClassName,
  iconStyle,
  inverted,
  type = 'button',
  title,
  ...props
}: PropsWithChildren<BaseButtonProps<T>>) {
  const options: ComponentStylingOptions = {
    classNames: cs(
      className,
      'inline-block font-medium flex-shrink-0',
      'w-8 h-8 rounded-full',
      'text-button',
      {
        'hover:text-primary hover:bg-primaryInverted hover:bg-opacity-5 border-primary':
          !inverted,
      },
      {
        'text-primaryInverted hover:bg-primary hover:bg-opacity-5 border-primary':
          inverted,
      },
      'transition-colors duration-150 ease-in-out',
      {
        'opacity-50': disabled,
        border: border,
      },
    ),
    style: {
      lineHeight: '100%',
      ...style,
    },
  };
  const content = (
    <Component
      className={options.classNames}
      style={options.style}
      disabled={disabled}
      type={type}
      title={title}
      {...componentProps}
      {...props}
    >
      <span className="flex h-full items-center justify-center">
        <Icon className={iconClassName} style={iconStyle} />
      </span>
    </Component>
  );

  if (!title) return content;

  return (
    <Tooltip title={title} position="bottom">
      {content}
    </Tooltip>
  );
}

export default IconButton;
