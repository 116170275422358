import { FunctionComponent } from 'react';
import Breadcrumb from './Breadcrumb';
import Separator from './Separator';

export type BreadcrumbItem = {
  title: string;
  href: string;
};

export type Props = {
  items: BreadcrumbItem[];
};

const Breadcrumbs: FunctionComponent<Props> = ({ items }) => {
  const lastItem = items[items.length - 1];
  return (
    <nav>
      <ol className="flex">
        {items.map((item) => {
          return (
            <li key={item.title}>
              <Breadcrumb item={item} isActive={item === lastItem} />
              {item !== lastItem ? <Separator /> : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
