import Link from '@components/link/Link';
import React, { useCallback } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { logout } from '../../utilities/request/auth';
import { Routes, linkTo } from '../../utilities/routes';
import { Menu } from './Menu';

const PackfleetLogo = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M200 0H0.0564664V26.4317H173.224V173.568H26.834V130.447H55.0354C62.6698 130.447 69.1646 129.378 74.52 127.241C79.8755 124.991 84.2054 122.067 87.5098 118.467C90.9281 114.868 93.3779 110.762 94.8592 106.151C96.4544 101.427 97.2521 96.646 97.2521 91.8093C97.2521 86.8601 96.4544 82.0797 94.8592 77.4679C93.3779 72.8562 90.9281 68.7506 87.5098 65.1512C84.2054 61.5518 79.8755 58.6835 74.52 56.5463C69.1646 54.2967 62.6698 53.1719 55.0354 53.1719H0V173.64H0.0475206V200H200V0ZM26.834 73.756V109.863H47.686C50.7625 109.863 53.7251 109.638 56.5737 109.188C59.4223 108.738 61.9291 107.894 64.0941 106.657C66.259 105.307 67.9682 103.451 69.2216 101.089C70.5889 98.7269 71.2726 95.6337 71.2726 91.8093C71.2726 87.9849 70.5889 84.8917 69.2216 82.5296C67.9682 80.1675 66.259 78.3678 64.0941 77.1305C61.9291 75.7807 59.4223 74.8808 56.5737 74.4309C53.7251 73.981 50.7625 73.756 47.686 73.756H26.834Z"
      fill="#FFFFFF"
    />
  </svg>
);

export const Navbar = () => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const user = useCurrentUser();
  const isProduction = environment === 'production';
  const logoutUser = useCallback(async () => {
    await logout();
    // hard reload so entire app is re-loaded and tokens reset
    window.location.href = linkTo(Routes.home);
  }, []);

  return (
    <div className="fixed flex h-[64px] w-screen items-center justify-between px-4 py-4 md:h-screen md:w-[64px] md:flex-col">
      <Link
        href={Routes.dashboard}
        className="flex h-8 w-8 items-center justify-center rounded-md bg-brand"
      >
        <PackfleetLogo />
      </Link>
      {environment && !isProduction ? (
        <div className="mt-2 hidden rounded-md bg-warningLight px-2 py-1 text-sm uppercase md:absolute md:top-14">
          {environment.slice(0, 3)}
        </div>
      ) : null}

      <Menu />

      <div className="grow flex md:flex-col justify-end">
        {user ? (
          <button onClick={() => logoutUser()}>
            <div className="flex items-center rounded-full bg-infoLight/50 p-2">
              {user.name.match(/\b(\w)/g)?.join('')}
            </div>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
