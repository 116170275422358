import { PanelLevel } from '@components/panel/utils';
import useDebounce from 'hooks/useDebounce';
import { ANIMATION_TIME, AnimatedPanel } from '../../panel/AnimatedPanel';
import { TaskDetailsContent } from './TaskDetailsContent';
import { useTasksContext } from './useTasksContext';

export const TasksDetail = () => {
  const { selectedTask } = useTasksContext();
  const debouncedSelectedTask = useDebounce(selectedTask, ANIMATION_TIME);

  return (
    <AnimatedPanel
      isOpen={selectedTask != null}
      level={PanelLevel.TASK_DETAILS}
      isOffsetByDefault={true}
    >
      {(translateButtons) => (
        <TaskDetailsContent
          selectedTask={selectedTask || debouncedSelectedTask}
          translateButtons={translateButtons}
        />
      )}
    </AnimatedPanel>
  );
};
