import cs from 'classnames';
import React, { FunctionComponent } from 'react';
import Spinner, { SpinnerColor, SpinnerSize } from './Spinner';

export type Props = {
  when?: boolean;
  full?: boolean;
  className?: string;
  size?: SpinnerSize;
  color?: SpinnerColor;
  noMargin?: boolean;
  text?: string;
};

const Loading: FunctionComponent<Props> = ({
  when,
  full = false,
  className,
  size,
  color,
  noMargin,
  text,
}) => {
  return (
    <div
      className={cs(className, 'flex', {
        'w-full': full,
        'my-4': !noMargin,
        hidden: !when,
      })}
    >
      <Spinner size={size} color={color} />
      {text ? <span className="ml-2">{text}</span> : null}
    </div>
  );
};

export default Loading;
