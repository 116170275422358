import Skeleton from '@components/skeleton/Skeleton';
import { useReadCollectionLocationQuery } from 'generated/graphql';
import Link from 'next/link';
import { Routes, linkTo } from 'utilities/routes';
import { truthy } from 'utilities/truthy';

type Props = {
  collectionLocationId: string;
};
export const CollectionLocationDetails = ({ collectionLocationId }: Props) => {
  const { data, loading, error } = useReadCollectionLocationQuery({
    variables: { input: { id: collectionLocationId } },
  });
  const collectionLocation = data?.readCollectionLocation?.collectionLocation;

  return (
    <div className="px-3">
      {loading ? <Skeleton height={75} /> : null}
      {error?.message ? <p className="text-warning">{error.message}</p> : null}
      {collectionLocation ? (
        <Link
          className="border border-secondary rounded p-4 block"
          href={linkTo(Routes.merchantCollectionLocation, {
            id: collectionLocation?.organization.id,
            locId: collectionLocationId,
          })}
        >
          <div className="flex flex-col gap-1">
            <p className="flex gap-2 items-baseline">
              <span className="text-lg">{collectionLocation?.name}</span>
            </p>
            <p className="text-sm">
              {[
                collectionLocation.address.line1,
                collectionLocation.address.line2,
                collectionLocation.address.line3,
                collectionLocation.address.city,
                collectionLocation.address.postCode,
              ]
                .filter(truthy)
                .join(', ')}
            </p>
            <p className="text-secondary text-sm">
              Merchant:{' '}
              <Link
                href={linkTo(Routes.merchant, {
                  id: collectionLocation.organization.id,
                })}
                className="text-link"
              >
                {collectionLocation.organization.name}
              </Link>
            </p>
          </div>
        </Link>
      ) : null}
    </div>
  );
};
