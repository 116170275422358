import { TZ_EUROPE_LONDON } from '@packfleet/datetime';

function getDeviceTimezone(): string {
  if (
    // CanIUse suggests we can use this for all recent browsers / mobile browsers, but this doesn't work in IE.
    // In IE, we'd default to London instead which is fine.
    typeof Intl !== 'undefined' &&
    typeof Intl.DateTimeFormat !== 'undefined' &&
    typeof Intl.DateTimeFormat().resolvedOptions !== 'undefined' &&
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return TZ_EUROPE_LONDON;
}

export const TZ = getDeviceTimezone();

export const TIMEZONES =
  Intl && typeof Intl.supportedValuesOf === 'function'
    ? Intl.supportedValuesOf('timeZone')
    : [];
