import cs from 'classnames';
import React, { FunctionComponent } from 'react';
import { ReusableComponentProps } from '../types';

export type Props = ReusableComponentProps & React.HTMLProps<HTMLLabelElement>;

const FormLabel: FunctionComponent<Props> = ({
  className,
  style,
  children,
  ...props
}) => {
  return (
    <label
      className={cs('block text-sm font-medium text-brandDark', className)}
      style={style}
      {...props}
    >
      {children}
    </label>
  );
};

export default FormLabel;
