import {
  PackStatus,
  ShipmentOnStartedRouteInDepotFragment,
  useMarkShipmentsMutation,
  useRemoveRouteActivityFromVehicleMutation,
  useShipmentLazyQuery,
} from 'generated/graphql';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { ShipmentNotes } from './utils/ShipmentNotes';
import { ShipmentStatusHistory } from './utils/ShipmentStatusHistory';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: ShipmentOnStartedRouteInDepotFragment;
  taskId: string;
};

export const ShipmentOnStartedRouteInDepot = ({ task, taskId }: Props) => {
  const [scanPacks] = useMarkShipmentsMutation();
  const [removeRouteActivityFromVehicle] =
    useRemoveRouteActivityFromVehicleMutation();
  const [getShipment] = useShipmentLazyQuery();

  const onClickMarkAsOutForDelivery = async () => {
    const { data } = await getShipment({ variables: { id: task.shipmentId } });
    const packs =
      data?.shipment?.packs
        .map((p, index) => ({ ...p, packNumber: index + 1 }))
        .filter((p) => p.status === PackStatus.InDepot) || [];

    return extractGraphQLErrorIfExists(
      scanPacks({
        variables: {
          input: {
            scannedPacks: packs.map((p) => ({
              trackingNumber: task.trackingPhrase,
              packNumber: p.packNumber,
              status: PackStatus.OutForDelivery,
            })),
          },
        },
      }),
      'scanPacks',
    );
  };

  const onClickRemoveFromRoute = () =>
    extractGraphQLErrorIfExists(
      removeRouteActivityFromVehicle({
        variables: {
          input: {
            activityId: task.routeActivity.routeActivityId.toString(),
            optimizedRouteVehicleId: task.routeActivity.vehicleId.toString(),
            date: task.routeActivity.date,
          },
        },
      }),
      'removeRouteActivityFromVehicle',
    );

  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentDetails shipmentId={task.shipmentId} />

      <ShipmentNotes shipmentId={task.shipmentId} />

      <SectionHeading title="Context" />
      <ShipmentStatusHistory shipmentId={task.shipmentId} />

      <RouteActivity routeActivity={task.routeActivity} title="Current route" />

      <SectionHeading title="Actions" />

      <TaskActionButton
        role="primary"
        text="Mark as Out for Delivery"
        onClick={onClickMarkAsOutForDelivery}
        taskIdToCompleteOnClick={taskId}
      />
      <TaskActionButton
        role="destructive"
        text="Remove from route"
        onClick={onClickRemoveFromRoute}
        taskIdToCompleteOnClick={taskId}
      />
    </div>
  );
};
