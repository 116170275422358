import Dialog from '@/components/dialog/Dialog';
import Button from '@components/button/Button';
import { Loading } from '@packfleet/ui';
import Link from 'next/link';
import { ReactNode, useState } from 'react';
import { getErrorMessage } from 'utilities/errors/get-error-message';
import { useTasksContext } from '../../useTasksContext';

type Props = {
  text: string;
  role: 'primary' | 'secondary' | 'destructive';
} & (
  | {
      taskIdToCompleteOnClick?: string;
      onClick: () => Promise<void | { error?: string }>;
      href?: undefined;
      dialog?: {
        title: string;
        description?: string;
        body?: ReactNode;
      } | null;
    }
  | {
      taskIdToCompleteOnClick?: undefined;
      onClick?: undefined;
      href: string;
      dialog?: undefined;
    }
);

export const TaskActionButton = ({
  text,
  onClick,
  role,
  taskIdToCompleteOnClick,
  href,
  dialog,
}: Props) => {
  const { action } = useTasksContext();
  const color =
    role === 'primary' ? 'info' : role === 'destructive' ? 'danger' : 'primary';
  const mode = role === 'secondary' ? 'outline' : 'primary';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>('');

  const handleClick = onClick
    ? async () => {
        setLoading(true);
        setError(null);
        try {
          const res = await onClick();
          if (res?.error) {
            setError(res.error);
          } else if (taskIdToCompleteOnClick) {
            action.optimisticCompletion(taskIdToCompleteOnClick);
          }
        } catch (error) {
          setError(getErrorMessage(error));
        } finally {
          setLoading(false);
        }
      }
    : undefined;

  const trigger = (
    <Button
      component={onClick ? 'button' : Link}
      href={href}
      onClick={dialog ? undefined : handleClick}
      className="w-full"
      color={color}
      s="small"
      mode={mode}
      disabled={loading}
    >
      {!dialog ? <Loading when={loading} className="mr-2" /> : null} {text}
    </Button>
  );

  return (
    <div className="px-3">
      <p className="text-danger">{error}</p>
      {dialog && handleClick ? (
        <Dialog
          title={dialog.title}
          description={dialog.description}
          confirmText={text}
          confirmColor={role === 'destructive' ? 'danger' : 'info'}
          trigger={trigger}
          onConfirm={handleClick}
        >
          {dialog.body}
        </Dialog>
      ) : (
        trigger
      )}
    </div>
  );
};
