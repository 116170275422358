import Heading from '@components/heading/Heading';
import Loading from '@components/loading/Loading';
import { formatFriendlyIso8601DateTimeStr } from '@packfleet/datetime';
import { useShipmentQuery } from 'generated/graphql';
import { formatPackStatus } from 'utilities/shipments';

type Props = {
  shipmentId: string;
};

export const ShipmentStatusHistory = ({ shipmentId }: Props) => {
  const { data, loading, error } = useShipmentQuery({
    variables: { id: shipmentId },
  });
  const shipment = data?.shipment;

  return (
    <div className="px-3 flex flex-col gap-4">
      <Loading when={loading} className="items-center justify-center" />
      {error?.message ? <p className="text-warning">{error.message}</p> : null}
      {shipment?.packs.map((pack, index) => (
        <div key={pack.id}>
          {shipment.packs.length > 1 ? (
            <Heading level={4}>Pack {index + 1}</Heading>
          ) : null}
          {pack.statusHistory?.map((s) => {
            return (
              <div key={s.id}>
                <p className="mt-2">
                  {formatPackStatus(s.from)} -&gt; {formatPackStatus(s.to)}
                </p>
                <p className="text-sm text-secondary">
                  {formatFriendlyIso8601DateTimeStr(s.createdAt)}{' '}
                  {s.createdBy?.name ? `by ${s.createdBy.name}` : null}
                </p>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
