import { CommandMenuContext } from '@components/command-palette/CommandPalette';
import { useContext } from 'react';

export function useCommandContext() {
  const context = useContext(CommandMenuContext);
  if (!context) {
    throw new Error('Command menu requires a context');
  }
  return context;
}
