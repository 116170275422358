import {
  AddressFeedbackType,
  ProofOfCompletionStatus,
} from 'generated/graphql';

export const getTypeFriendlyName = (type: AddressFeedbackType) => {
  switch (type) {
    case AddressFeedbackType.HardToFindParking:
      return 'Hard to find parking';
    case AddressFeedbackType.SomethingElse:
      return 'Something else';
    case AddressFeedbackType.WrongAddress:
      return 'Wrong address';
    case AddressFeedbackType.DifficultToFindNeighbour:
      return 'Difficult to find neighbour';
    case AddressFeedbackType.HardToFollowAccessInstructions:
      return 'Hard to follow access instructions';
    case AddressFeedbackType.ReceptionWouldntTakeIt:
      return "Reception wouldn't take it";
    case AddressFeedbackType.TowerBlockChallenging:
      return 'Tower block challenging';
  }

  const exhaustiveCheck: never = type; // If this line errors we missed a case
  throw new Error(`Unknown type: ${exhaustiveCheck}`);
};

export const getPodFriendlyName = (
  status: ProofOfCompletionStatus | null | undefined,
) => {
  if (!status) {
    return undefined;
  }

  switch (status) {
    case ProofOfCompletionStatus.Collected:
      return 'Collected';
    case ProofOfCompletionStatus.DeliveredToRecipient:
      return 'Delivered to recipient';
    case ProofOfCompletionStatus.LeftInSafePlace:
      return 'Left in safe place';
    case ProofOfCompletionStatus.LeftWithNeighbour:
      return 'Left with neighbour';
    case ProofOfCompletionStatus.LeftWithReception:
      return 'Left with reception';
    case ProofOfCompletionStatus.NobodyHomeReattemptLater:
      return 'Nobody home, weʼll reattempt later';
    case ProofOfCompletionStatus.NothingToCollect:
      return 'Nothing to collect';
    case ProofOfCompletionStatus.UnableToCollectSeeNotes:
      return 'Unable to collect, see notes';
    case ProofOfCompletionStatus.UnableToDeliverReattemptLater:
      return 'Unable to deliver, weʼll reattempt later';
    case ProofOfCompletionStatus.UnableToDeliverSeeNotes:
      return 'Unable to deliver, see notes';
    case ProofOfCompletionStatus.ReturnCollected:
      return 'Return collected';
  }

  const exhaustiveCheck: never = status; // If this line errors we missed a case
  throw new Error(`Unknown status: ${exhaustiveCheck}`);
};
