import { AddInternalNoteDialog } from '@components/shipment/AddInternalNoteDialog';
import Skeleton from '@components/skeleton/Skeleton';
import {
  formatFriendlyIso8601DateTimeStr,
  formatRelativeIso8601DateTimeStr,
} from '@packfleet/datetime';
import { Tooltip } from '@packfleet/ui';
import { useShipmentQuery } from 'generated/graphql';
import { FiExternalLink } from 'react-icons/fi';
import { SectionHeading } from './SectionHeading';

type Props = {
  shipmentId: string;
};
export const ShipmentNotes = ({ shipmentId }: Props) => {
  const { data, loading, error } = useShipmentQuery({
    variables: { id: shipmentId },
  });
  const shipment = data?.shipment;

  return (
    <div>
      <SectionHeading title="Notes" />
      <div className="px-3">
        {loading ? (
          <div className="py-4">
            <Skeleton height={30} />
          </div>
        ) : null}
        {error?.message ? (
          <p className="text-warning">{error.message}</p>
        ) : null}
        {shipment?.internalNotes.length ? (
          <div className="py-4 flex flex-col gap-4">
            {shipment.internalNotes.map((note) => (
              <div key={note.id}>
                {note.link ? (
                  <a
                    href={note.link}
                    className="text-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {note.text}{' '}
                    <FiExternalLink className="inline text-sm mb-1" />
                  </a>
                ) : (
                  <p className="italic">{note.text}</p>
                )}
                <Tooltip
                  title={formatFriendlyIso8601DateTimeStr(note.createdAt)}
                >
                  <p className="text-secondary text-left text-sm">
                    {note.user?.name},{' '}
                    {formatRelativeIso8601DateTimeStr(note.createdAt)}
                  </p>
                </Tooltip>
              </div>
            ))}
          </div>
        ) : null}
        {!loading ? (
          <AddInternalNoteDialog
            shipmentId={shipmentId}
            trigger={
              <div className="py-4">
                <button className="font-medium flex-shrink-0 w-full bg-secondary text-primary text-sm h-8 rounded px-2.5 flex items-center justify-center">
                  + Add
                </button>
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};
