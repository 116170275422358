import { Temporal } from '@js-temporal/polyfill';
import { parsePlainDateTime } from '@packfleet/datetime';
import { DayOfWeek } from 'generated/graphql';

export function getDayOfWeek(d: Date): DayOfWeek {
  return DAYS_OF_WEEK[d.getDay()];
}

export function getDayOfWeekOfPlainDate(d: Temporal.PlainDate): DayOfWeek {
  return DAYS_OF_WEEK[d.dayOfWeek % 7];
}

export const DAYS_OF_WEEK = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];

export const HUMAN_ORDERED_DAYS_OF_WEEK = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

// As per https://tc39.es/proposal-temporal/docs/plaindate.html#dayOfWeek
export const DAY_OF_WEEK_NUMBER: Record<DayOfWeek, number> = {
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
  [DayOfWeek.Sunday]: 7,
};

// Used for the format that comes out of datetime-local input elements
export const parseDateTimeLocalWithTimezone = (value: string, tz: string) =>
  parsePlainDateTime(value).toZonedDateTime(tz).toInstant();

export const nowDatetimeLocal = () =>
  Temporal.Now.zonedDateTimeISO().toString().substring(0, 16);
