import Button from '@components/button/Button';
import Dialog from '@components/dialog/Dialog';
import classNames from 'classnames';
import useDebounce from 'hooks/useDebounce';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { IconType } from 'react-icons';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useRequiredContext } from 'utilities/context';

type Props = {
  buttonText: ReactNode;
  children: ReactNode;
  direction?: 'up' | 'down';
  align?: 'left' | 'right';
  onDialogOpen?: () => void;
  wide?: boolean;
  color?:
    | 'info'
    | 'warning'
    | 'danger'
    | 'success'
    | 'brand'
    | 'neutral'
    | 'primary';
  className?: string;
  textAlign?: 'left' | 'center';
  disabled?: boolean;
};

const MenuContext = createContext<{
  setIsDialogOpen: (isOpen: boolean) => void;
  textAlign: 'left' | 'center';
} | null>(null);

export const ContextMenu = ({
  buttonText,
  children,
  onDialogOpen,
  direction = 'up',
  align = 'left',
  color = 'primary',
  className,
  textAlign = 'left',
  wide,
  disabled = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  const debouncedIsOpen = useDebounce(open, 200);

  useEffect(() => {
    const elem = ref.current;
    const menu = menuRef.current;

    if (elem) {
      const onFocusOut = () => setOpen(false);
      const onFocusIn = () => setOpen(true);
      elem.addEventListener('focusout', onFocusOut);
      menu?.addEventListener('focusin', onFocusIn);
      return () => {
        elem.removeEventListener('focusout', onFocusOut);
        menu?.removeEventListener('focusin', onFocusIn);
      };
    }
  }, [setOpen]);

  useEffect(() => {
    if (isDialogOpen) {
      onDialogOpen?.();
    }
  }, [isDialogOpen]);

  const showMenu = open || isDialogOpen || debouncedIsOpen;

  return (
    <MenuContext.Provider value={{ setIsDialogOpen, textAlign }}>
      <div className={classNames('relative', className)} ref={ref}>
        <Button
          s="smaller"
          onClick={toggleOpen}
          color={color}
          mode="outline"
          disabled={disabled}
        >
          <span className="flex items-center gap-1">
            {buttonText} {showMenu ? <IoChevronUp /> : <IoChevronDown />}
          </span>
        </Button>
        <div ref={menuRef}>
          {showMenu ? (
            <div
              className={classNames(
                'absolute bg-primary z-50 shadow border border-light rounded flex flex-col items-stretch',
                {
                  'w-52': !wide,
                  'w-64': wide,
                  'bottom-10': direction === 'up',
                  'top-10': direction === 'down',
                  'left-0': align === 'left',
                  'right-0': align === 'right',
                },
              )}
            >
              {children}
            </div>
          ) : null}
        </div>
      </div>
    </MenuContext.Provider>
  );
};

type ContextMenuItemProps = {
  id: string;
  text: ReactNode;
  onClick: () => Promise<{ error?: string } | undefined> | void;
  icon?: IconType;
  color?: 'success' | 'info' | 'warning' | 'danger' | 'neutral' | 'brand';
  mode?: 'primary' | 'outline' | 'text';
  className?: string;
  confirmationDialog?: {
    title?: string;
    description?: string;
    confirmText?: string;
    cancelText?: string;
    confirmColor?: 'success' | 'info' | 'warning' | 'danger' | 'brand';
    content?: ReactNode;
    disabled?: boolean;
  };
};

export const ContextMenuItem = ({
  text,
  onClick,
  icon: Icon,
  confirmationDialog,
  color = 'neutral',
  mode = 'text',
  id,
  className,
}: ContextMenuItemProps) => {
  const { setIsDialogOpen, textAlign } = useRequiredContext(MenuContext);
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    await onClick();
    setIsDialogOpen(false);
    setLoading(false);
  }, [onClick]);

  const inner = (
    <Button
      key={id}
      className={classNames('flex justify-start p-2 m-2 group', className, {
        'hover:bg-secondary': color === 'neutral',
        'justify-start': textAlign === 'left',
        'justify-center': textAlign === 'center',
      })}
      onClick={confirmationDialog ? undefined : handleClick}
      mode={mode}
      color={color}
      s="smaller"
      loading={loading}
      disabled={loading}
    >
      {text} {Icon ? <Icon /> : null}
    </Button>
  );

  if (confirmationDialog) {
    return (
      <Dialog
        key={id}
        trigger={inner}
        onOpenChange={setIsDialogOpen}
        title={
          confirmationDialog.title || (typeof text === 'string' ? text : '')
        }
        description={confirmationDialog.description || ''}
        onConfirm={onClick}
        cancelText={confirmationDialog.cancelText}
        confirmText={confirmationDialog.confirmText}
        confirmColor={confirmationDialog.confirmColor}
        disabled={confirmationDialog.disabled}
        async
      >
        {confirmationDialog.content}
      </Dialog>
    );
  }

  return inner;
};
