import { ReactNode, useCallback, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

import Button from '@components/button/Button';
import Dialog from '@components/dialog/Dialog';
import Heading from '@components/heading/Heading';
import Input from '@components/input/Input';
import { Panel } from '@components/panel/Panel';
import { isDateBefore, parsePlainDate, todayLocal } from '@packfleet/datetime';

import { useCompleteTaskMutation } from 'generated/graphql';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { TZ } from 'utilities/timezone';
import { getRefetchQueries, getTaskInfo } from '../utils';
import { SnoozeMenu } from './SnoozeMenu';
import { TaskSpecificContent } from './task-content/TaskSpecificContent';
import { EnrichedTaskInfo, useTasksContext } from './useTasksContext';

type Props = {
  selectedTask: EnrichedTaskInfo | null | undefined;
  translateButtons: ReactNode;
};

export const TaskDetailsContent = ({
  selectedTask,
  translateButtons,
}: Props) => {
  const { action } = useTasksContext();
  const [completeTask] = useCompleteTaskMutation();
  const [completionResult, setCompletionResult] = useState('');

  const onComplete = useCallback(async () => {
    if (selectedTask) {
      return extractGraphQLErrorIfExists(
        completeTask({
          variables: { input: { completionResult, taskId: selectedTask.id } },
          refetchQueries: getRefetchQueries(selectedTask.id),
        }),
        'completeTask',
      );
    }
    return {};
  }, [completionResult, selectedTask]);

  if (!selectedTask) {
    return null;
  }

  const { title, description, dismissActionEnabled } = getTaskInfo(
    selectedTask?.task,
  );

  const isRoutingDateBeforeToday =
    !!selectedTask.routingDate &&
    isDateBefore(parsePlainDate(selectedTask.routingDate), todayLocal(TZ));

  return (
    <Panel
      title=""
      actionsLeft={translateButtons}
      actionsRight={
        <button
          className="py-1 px-2 shadow rounded-lg border border-light w-[32px] h-[32px] shrink-0"
          onClick={() => action.selectTask(null)}
        >
          <IoMdClose />
        </button>
      }
    >
      <div className="w-full flex flex-col grow">
        <Heading level={2} className="px-3 pb-2 pt-4" headingStyle="heading3">
          {title}
        </Heading>
        <p className="px-3 text-secondary">{description}</p>
        <div className="grow overflow-y-auto basis-0">
          <TaskSpecificContent
            task={selectedTask.task}
            taskId={selectedTask.id}
            createdByName={selectedTask.createdByName}
            createdByUserId={selectedTask.createdByUserId}
            createdAt={selectedTask.createdAt}
          />
        </div>
        <div className="min-h-[56px] px-3 py-2 border-t border-light flex items-center justify-between">
          <SnoozeMenu
            taskId={selectedTask.id}
            isSnoozed={selectedTask.isSnoozed}
            snoozeUntil={selectedTask.snoozeUntil}
          />
          {dismissActionEnabled || isRoutingDateBeforeToday ? (
            <Dialog
              title="Dismiss Task"
              description=""
              onConfirm={onComplete}
              trigger={
                <Button s="smaller" color="neutral" mode="outline">
                  Dismiss Task
                </Button>
              }
            >
              <label>
                Notes
                <Input
                  required
                  className="w-full"
                  value={completionResult}
                  onChange={(e) => setCompletionResult(e.currentTarget.value)}
                />
              </label>
            </Dialog>
          ) : null}
        </div>
      </div>
    </Panel>
  );
};
