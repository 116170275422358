type Props = {
  title: 'Details' | 'Context' | 'Notes' | 'Actions';
};

export const SectionHeading = ({ title }: Props) => {
  return (
    <div className="border-b border-secondary px-3 pt-4">
      <h3 className="text-secondary">{title}</h3>
    </div>
  );
};
