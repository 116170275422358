import { GraphQLError } from 'graphql';
import { Error } from '../../generated/graphql';
import { FirebaseAuthError } from './firebase-auth.errors';
import { getErrorMessage } from './get-error-message';

export function getErrorCode(e: {
  code?: FirebaseAuthError;
}): FirebaseAuthError | null {
  if (typeof e.code !== 'undefined') {
    return e.code;
  }
  return null;
}

type Payload<T extends string> =
  | {
      data?: Partial<Record<T, any>> | null;
      errors?: readonly GraphQLError[] | null;
    }
  | null
  | undefined;

export async function extractGraphQLErrorIfExists<T extends string>(
  prms: Payload<T> | Promise<Payload<T>>,
  access: T,
): Promise<{ error?: string }> {
  let rsp: Payload<T>;
  try {
    rsp = await prms;
  } catch (error) {
    return { error: getErrorMessage(error) };
  }

  if (!rsp) return {};
  const potentialError = rsp.data
    ? rsp.data[access].error
      ? (rsp.data[access].error as Error | { __typename?: string })
      : (rsp.data[access] as Error | { __typename?: string })
    : undefined;
  const err = getUserFacingError(potentialError);
  if (err) {
    return { error: err.message };
  }
  if (rsp.errors?.length) {
    const err = rsp.errors[0];
    return { error: err.message };
  }

  return {};
}

export function getUserFacingError(
  obj: Error | { __typename?: string } | null | undefined,
) {
  if (!obj) return null;
  if (isError(obj)) {
    return obj;
  }
  return null;
}

function isError(obj: Error | { __typename?: string }): obj is Error {
  return obj.__typename === 'Error';
}
