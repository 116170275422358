import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';

export type Props = BaseButtonProps<HTMLButtonElement> & {
  type?: 'button' | 'submit';
};

export const Button = ({ type = 'button', children, ...props }: Props) => {
  return (
    <BaseButton component={'button'} type={type} {...props}>
      {children}
    </BaseButton>
  );
};

export default Button;
