import cs from 'classnames';
import { VoidFunctionComponent } from 'react';

const Separator: VoidFunctionComponent = () => {
  return (
    <span aria-hidden={true} className={cs('mx-2 text-secondary')}>
      /
    </span>
  );
};

export default Separator;
