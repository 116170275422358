import cs from 'classnames';
import { FunctionComponent } from 'react';
import { Colors } from '../../utilities/colors';

export type SpinnerSize = 'large' | 'normal' | 'small' | 'smaller' | 'tiny';
export type SpinnerColor = 'light' | 'dark';

export type Props = {
  className?: string;
  size?: SpinnerSize;
  color?: SpinnerColor;
};

const Spinner: FunctionComponent<Props> = ({
  className,
  size = 'normal',
  color = 'dark',
}) => {
  const hexColor = color === 'light' ? Colors.white : Colors.brandDark;
  const sizeClasses = {
    tiny: 'w-3 h-3',
    smaller: 'w-4 h-4',
    small: 'w-5 h-5',
    normal: 'w-6 h-6',
    large: 'w-8 h-8',
  };
  return (
    <div
      className={cs(
        className,
        sizeClasses[size],
        'relative inline-block overflow-hidden rounded-full',
      )}
      style={{
        borderTop: `2px solid ${hexColor}`,
        borderRight: `2px solid ${hexColor}`,
        borderBottomStyle: 'solid',
        borderLeftStyle: 'solid',
        borderBottomWidth: 2,
        borderLeftWidth: 2,
        borderBottomColor: 'rgba(0, 0, 0, 0)',
        borderLeftColor: 'rgba(0, 0, 0, 0)',
        animation: '0.45s linear 0s infinite spinner',
      }}
    >
      {/* For accessibility */}
      <span
        className="absolute block overflow-hidden"
        style={{ height: 1, width: 1, top: -1, left: -1 }}
      >
        Loading
      </span>
    </div>
  );
};

export default Spinner;
