import { Temporal } from '@js-temporal/polyfill';
import { ComponentProps, useState } from 'react';

import { TZ } from '@/utilities/timezone';
import Input from '@components/input/Input';
import {
  ContextMenu,
  ContextMenuItem,
} from '@components/routing/menu/ContextMenu';
import {
  TZ_EUROPE_LONDON,
  formatFriendlyInstant,
  now,
  nowLocal,
} from '@packfleet/datetime';
import { useSnoozeTaskMutation } from 'generated/graphql';
import {
  nowDatetimeLocal,
  parseDateTimeLocalWithTimezone,
} from 'utilities/date';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { getRefetchQueries } from '../utils';
import { useTasksContext } from './useTasksContext';

type DurationOptions =
  | '20 minutes'
  | '1 hour'
  | '3 hours'
  | 'tomorrow'
  | 'next week'
  | 'custom'
  | 'clear';

function getSnoozeUntil(
  duration: DurationOptions,
  snoozeUntil: string,
): Temporal.Instant | null {
  if (duration === '20 minutes') return now().add({ minutes: 20 });
  if (duration === '1 hour') return now().add({ hours: 1 });
  if (duration === '3 hours') return now().add({ hours: 3 });

  const timeToSnoozeTo = Temporal.PlainTime.from('08:00');
  const nowDateTime = nowLocal(TZ_EUROPE_LONDON);
  if (duration === 'tomorrow') {
    const tomorrow = nowDateTime.add({ days: 1 }).withPlainTime(timeToSnoozeTo);
    return tomorrow.toInstant();
  }

  if (duration === 'next week') {
    const days = 8 - nowDateTime.dayOfWeek;
    const nextWeek = nowDateTime.add({ days }).withPlainTime(timeToSnoozeTo);
    return nextWeek.toInstant();
  }

  if (duration === 'clear') {
    return null;
  }

  // Doing this calculation on the client so it takes into account
  // the user's timezone
  return parseDateTimeLocalWithTimezone(snoozeUntil, TZ);
}

const confirmationDialog: ComponentProps<
  typeof ContextMenuItem
>['confirmationDialog'] = {
  confirmText: 'Snooze',
  confirmColor: 'info',
  cancelText: 'Cancel',
  description:
    "Snoozed tasks aren't shown prominently and are hidden from notifications.",
};

type Props = {
  taskId: string;
  snoozeUntil: Temporal.Instant | undefined;
  isSnoozed: boolean;
};

export const SnoozeMenu = ({ taskId, isSnoozed, snoozeUntil }: Props) => {
  const { action } = useTasksContext();
  const [snoozeTask] = useSnoozeTaskMutation();
  const [snoozeUntilDateTime, setSnoozeUntilDateTime] = useState(
    nowDatetimeLocal(),
  );

  const snoozeTaskFor = async (duration: DurationOptions) => {
    const until = getSnoozeUntil(duration, snoozeUntilDateTime);

    const res = await extractGraphQLErrorIfExists(
      snoozeTask({
        variables: {
          input: {
            taskId,
            until: until ?? now(),
          },
        },
        refetchQueries: getRefetchQueries(taskId),
      }),
      'snoozeTask',
    );
    if (until) action.selectTask(null);
    return res;
  };

  return (
    <ContextMenu
      buttonText={isSnoozed ? 'Snoozed' : 'Snooze'}
      onDialogOpen={() => setSnoozeUntilDateTime(nowDatetimeLocal())}
      color={isSnoozed ? 'info' : 'primary'}
      textAlign="left"
    >
      <ContextMenuItem
        id="20-minutes-snooze"
        text="for 20 minutes"
        onClick={() => snoozeTaskFor('20 minutes')}
        confirmationDialog={{
          ...confirmationDialog,
          title: 'Snooze for 20 minutes',
        }}
      />
      <ContextMenuItem
        id="1-hour-snooze"
        text="for 1 hour"
        onClick={() => snoozeTaskFor('1 hour')}
        confirmationDialog={{
          ...confirmationDialog,
          title: 'Snooze for 1 hour',
        }}
      />
      <ContextMenuItem
        id="3-hours-snooze"
        text="for 3 hours"
        onClick={() => snoozeTaskFor('3 hours')}
        confirmationDialog={{
          ...confirmationDialog,
          title: 'Snooze for 3 hours',
        }}
      />
      <ContextMenuItem
        id="tomorrow-snooze"
        text="until Tomorrow"
        onClick={() => snoozeTaskFor('tomorrow')}
        confirmationDialog={{
          ...confirmationDialog,
          title: 'Snooze until tomorrow',
        }}
      />
      <ContextMenuItem
        id="next-week-snooze"
        text="until Next week"
        onClick={() => snoozeTaskFor('next week')}
        confirmationDialog={{
          ...confirmationDialog,
          title: 'Snooze until next week',
        }}
      />
      <ContextMenuItem
        id="custom-snooze"
        text="Custom"
        onClick={() => snoozeTaskFor('custom')}
        confirmationDialog={{
          ...confirmationDialog,
          content: (
            <label className="flex gap-2 items-center">
              Snooze until
              <Input
                type="datetime-local"
                required
                value={snoozeUntilDateTime}
                onChange={(e) => setSnoozeUntilDateTime(e.currentTarget.value)}
                min={nowDatetimeLocal()}
              />
            </label>
          ),
        }}
      />
      {isSnoozed && snoozeUntil ? (
        <ContextMenuItem
          id="clear-snooze"
          text="Clear"
          onClick={() => snoozeTaskFor('clear')}
          confirmationDialog={{
            cancelText: "Don't clear",
            confirmColor: 'danger',
            confirmText: 'Clear',
            description: `This will un-snooze this task. It's currently snoozed until ${formatFriendlyInstant(
              snoozeUntil,
            )}`,
          }}
          color="danger"
        />
      ) : null}
    </ContextMenu>
  );
};
