import cs from 'classnames';
import { FunctionComponent } from 'react';
import Link from '../link/Link';
import { BreadcrumbItem } from './Breadcrumbs';

export type Props = {
  item: BreadcrumbItem;
  isActive: boolean;
};

const Breadcrumb: FunctionComponent<Props> = ({ item, isActive }) => {
  return (
    <span>
      {!isActive ? (
        <Link
          unstyled
          href={item.href}
          className={cs('text-secondary hover:text-brandDark hover:underline')}
        >
          {item.title}
        </Link>
      ) : (
        <span className="font-semibold">{item.title}</span>
      )}
    </span>
  );
};

export default Breadcrumb;
