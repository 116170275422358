import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

export type Props<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsCreatable extends boolean = false,
> = (IsCreatable extends true
  ? CreatableProps<Option, IsMulti, Group>
  : ReactSelectProps<Option, IsMulti, Group>) & { createable?: IsCreatable };

function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsCreatable extends boolean = false,
>({
  styles,
  createable,
  ...props
}: Props<Option, IsMulti, Group, IsCreatable>) {
  const Element = createable ? CreatableSelect : ReactSelect;
  return (
    <Element<Option, IsMulti, Group>
      {...props}
      menuPortalTarget={globalThis?.document?.querySelector<HTMLElement>(
        '#portal-target',
      )}
      styles={{
        ...styles,
        menuPortal: (base, ...args) => ({
          ...base,
          ...styles?.menuPortal?.(base, ...args),
          zIndex: 30, // ensure it sits above the dialog which is z-20
          pointerEvents: 'auto', // otherwise it can't be clicked due to inherited styles from the body
        }),
      }}
    />
  );
}

export default Select;
