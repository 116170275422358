import classNames from 'classnames';
import keyBy from 'lodash.keyby';
import { useMemo, useState } from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

import { Panel } from '@components/panel/Panel';
import {
  ContextMenu,
  ContextMenuItem,
} from '@components/routing/menu/ContextMenu';
import Skeleton from '@components/skeleton/Skeleton';
import SkeletonList from '@components/skeleton/SkeletonList';
import { formatRelativeInstant, parseInstant } from '@packfleet/datetime';
import { useTaskViewersSubscription } from 'generated/graphql';
import { getTaskInfo } from '../utils';
import { TaskViewer } from './TaskViewer';
import {
  filterNonUrgentTasks,
  filterOpenTasks,
  filterSnoozedTasks,
  useTasksContext,
} from './useTasksContext';

export const SidebarContent = () => {
  const {
    action,
    tasks,
    counts,
    error,
    state: { selectedTaskId, viewedTaskIds },
  } = useTasksContext();
  const [currentlyViewing, setCurrentlyViewing] = useState<
    'open' | 'snoozed' | 'non-urgent'
  >(counts?.open || !counts?.nonUrgent ? 'open' : 'non-urgent');
  const { data: taskViewersData } = useTaskViewersSubscription({
    shouldResubscribe: true,
  });

  const tasksWithViewers = useMemo(() => {
    const taskViewersById = keyBy(
      taskViewersData?.taskViewers?.taskViewInfo,
      (t) => t.taskId,
    );

    return tasks?.map((task) => ({
      ...task,
      viewers: taskViewersById[task.id]?.viewers,
    }));
  }, [taskViewersData, tasks]);

  const openTasksText = `${counts?.open ?? ''} Open`;
  const snoozedTasksText = `${counts?.snoozed ?? ''} Snoozed`;
  const nonUrgentTasksText = `${counts?.nonUrgent ?? ''} Non-urgent`;
  const menuButtonText =
    currentlyViewing === 'open'
      ? openTasksText
      : currentlyViewing === 'snoozed'
        ? snoozedTasksText
        : nonUrgentTasksText;

  return (
    <Panel
      title="Inbox"
      actionsLeft={null}
      actionsRight={
        <>
          <ContextMenu
            buttonText={
              <>
                {!counts ? <Skeleton containerClassName="w-4" /> : null}
                {menuButtonText}
              </>
            }
            direction="down"
            align="right"
          >
            {currentlyViewing !== 'open' ? (
              <ContextMenuItem
                id="open-tasks"
                text={openTasksText}
                onClick={() => setCurrentlyViewing('open')}
              />
            ) : null}
            {currentlyViewing !== 'snoozed' ? (
              <ContextMenuItem
                id="snoozed-tasks"
                text={snoozedTasksText}
                onClick={() => setCurrentlyViewing('snoozed')}
              />
            ) : null}
            {currentlyViewing !== 'non-urgent' ? (
              <ContextMenuItem
                id="non-urgent-tasks"
                text={nonUrgentTasksText}
                onClick={() => setCurrentlyViewing('non-urgent')}
              />
            ) : null}
          </ContextMenu>
          <button
            className="py-1 px-2 shadow rounded-lg border border-light"
            onClick={action.toggleSidebar}
          >
            <IoMdClose />
          </button>
        </>
      }
    >
      <div className="w-full flex flex-col overflow-y-auto">
        {error ? <div className="text-warning">{error}</div> : null}
        <div className="grow shrink flex flex-col items-stretch overflow-y-auto">
          {!tasksWithViewers ? (
            <SkeletonList count={5} className="p-2" height={78} />
          ) : null}
          {tasksWithViewers
            ?.filter(
              currentlyViewing === 'open'
                ? filterOpenTasks
                : currentlyViewing === 'snoozed'
                  ? filterSnoozedTasks
                  : filterNonUrgentTasks,
            )
            .map((taskInfo) => {
              const { title, subject } = getTaskInfo(taskInfo.task);
              return (
                <button
                  key={taskInfo.id}
                  onClick={() => action.selectTask(taskInfo.id)}
                  className={classNames(
                    'min-h-[78px] border-b border-light flex flex-col pr-4 pl-6 py-2 relative',
                    { 'bg-infoLight/50': selectedTaskId === taskInfo.id },
                  )}
                >
                  <div className="self-stretch flex items-center">
                    {!taskInfo.isRead && !viewedTaskIds[taskInfo.id] ? (
                      <BsFillCircleFill
                        className="shrink-0 text-[#2f4f7e] -ml-[1.3rem] w-[1.3rem]"
                        size={8}
                      />
                    ) : null}
                    <div className="grow flex justify-between gap-2 min-w-0">
                      <span className="font-bold text-brandDark text-left truncate text-sm">
                        {title}
                      </span>
                      <span className="text-secondaryInverted font-medium text-sm whitespace-nowrap">
                        {formatRelativeInstant(taskInfo.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className="text-xs text-secondary py-1 text-left">
                    {subject}
                  </div>
                  <div className="flex group">
                    {taskInfo.viewers?.map((v, index) => (
                      <TaskViewer
                        key={v.user.id}
                        index={index}
                        name={v.user.name}
                        updatedAt={parseInstant(v.updatedAt)}
                      />
                    ))}
                  </div>
                </button>
              );
            })}
        </div>
      </div>
    </Panel>
  );
};
