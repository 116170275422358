import { ProofOfCompletionReviewFragment } from 'generated/graphql';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';

type Props = {
  task: ProofOfCompletionReviewFragment;
  taskId: string;
};

export const ProofOfCompletionReview = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentDetails shipmentId={task.shipmentId} />

      <SectionHeading title="Context" />

      <p className="text-secondary px-3">
        This delivery was {Math.round(task.distanceFromExpectedArea)}m from
        where we expected it to be.
      </p>

      <RouteActivity
        title="View the delivery:"
        routeActivity={task.routeActivity}
      />
    </div>
  );
};
