import { ShipmentNoLocationInDepotFragment } from 'generated/graphql';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { ShipmentNotes } from './utils/ShipmentNotes';
import { ShipmentStatusHistory } from './utils/ShipmentStatusHistory';

type Props = {
  task: ShipmentNoLocationInDepotFragment;
  taskId: string;
};

export const ShipmentNoLocationInDepot = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentDetails shipmentId={task.shipmentId} />

      <ShipmentNotes shipmentId={task.shipmentId} />

      <SectionHeading title="Context" />
      <ShipmentStatusHistory shipmentId={task.shipmentId} />
    </div>
  );
};
