import Head from 'next/head';
import React, { FunctionComponent, ReactNode } from 'react';

const defaultTitle = 'Packfleet App';
const defaultDescription = '';

export type Meta = {
  description?: string;
  imageURL?: string;
  pageURL?: string;
};

export type Props = {
  title?: string;
  meta?: Meta;
  children?: ReactNode;
};

const HeadTag: FunctionComponent<Props> = ({
  title,
  meta: { description } = {},
  children,
}) => {
  return (
    <Head>
      <title>{title || defaultTitle}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0 maximum-scale=1.0, user-scalable=no"
      />
      <meta name="description" content={description || defaultDescription} />
      <meta name="robots" content="noindex, nofollow" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      {children}
    </Head>
  );
};

// A component to just replace the static title with a dynamic one within a page
export const PageTitle = (props: { children: ReactNode }) => {
  return (
    <Head>
      <title>{props.children}</title>
    </Head>
  );
};

export default HeadTag;
