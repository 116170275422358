import Checkbox from '@/components/checkbox/Checkbox';
import FormLabel from '@/components/form/FormLabel';
import Input from '@/components/input/Input';
import {
  InternalNoteType,
  useAddInternalNotesMutation,
  useBulkUpdateShipmentDateMutation,
} from '@/generated/graphql';
import { extractGraphQLErrorIfExists } from '@/utilities/errors/helpers';
import { pluralize } from '@/utilities/string';
import { Temporal } from '@js-temporal/polyfill';
import { formatPlainDate } from '@packfleet/datetime';
import { useSetState } from '@packfleet/hooks';
import classNames from 'classnames';
import { useState } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  date: Temporal.PlainDate;
  shipments: {
    id: string;
    trackingPhrase: string;
    organizationName: string;
    perishable: boolean;
  }[];
};

export const MoveDeliveryDateButton = ({ date, shipments }: Props) => {
  const [notes, setNotes] = useState('');
  const [selectedDate, setSelectedDate] = useState(() =>
    formatPlainDate(date.add({ days: 1 })),
  );
  const [excludedShipments, { toggle }] = useSetState<string>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [addInternalNotes] = useAddInternalNotesMutation({});
  const [bulkUpdateDate] = useBulkUpdateShipmentDateMutation({});

  const nonPerishableShipments = shipments.filter((s) => !s.perishable);

  if (!nonPerishableShipments.length) {
    return null;
  }

  return (
    <TaskActionButton
      text="Change delivery date"
      role="secondary"
      dialog={{
        title: 'Change delivery date',
        description: 'This will not impact perishable shipments.',
        body: (
          <div className="flex flex-col gap-4">
            <FormLabel>
              New delivery date
              <Input
                className="block"
                type="date"
                min={formatPlainDate(date.add({ days: 1 }))}
                max={formatPlainDate(date.add({ days: 3 }))}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.currentTarget.value)}
                required
              />
            </FormLabel>
            <FormLabel>
              Notes
              <textarea
                className="block w-full rounded border border-primary p-4 shadow-inner"
                value={notes}
                onChange={(e) => setNotes(e.currentTarget.value)}
                required
              />
            </FormLabel>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsExpanded(!isExpanded);
              }}
              type="button"
              className="text-left flex items-center gap-2"
            >
              <BiChevronRight
                className={classNames('transition-transform duration-200', {
                  'rotate-90': isExpanded,
                })}
              />
              {nonPerishableShipments.length}{' '}
              {pluralize(
                nonPerishableShipments.length,
                'shipment',
                'shipments',
              )}
            </button>
            {isExpanded ? (
              <ul>
                {nonPerishableShipments.map((shipment) => (
                  <li key={shipment.id}>
                    <Checkbox
                      id={shipment.id}
                      onChange={() => toggle(shipment.id)}
                      checked={!excludedShipments.has(shipment.id)}
                      className="my-1"
                      label={`${shipment.trackingPhrase} (${shipment.organizationName})`}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        ),
      }}
      onClick={async () => {
        const shipmentIds = nonPerishableShipments
          .filter((s) => !excludedShipments.has(s.id))
          .map((s) => s.id);

        const [bulkUpdateDateResponse, addInternalNotesResponse] =
          await Promise.all([
            bulkUpdateDate({
              variables: {
                input: {
                  updates: shipmentIds.map((shipmentId) => ({
                    shipmentID: shipmentId,
                    date: selectedDate,
                    updateEarliestDateToMatch: true,
                  })),
                },
              },
            }),
            addInternalNotes({
              variables: {
                input: {
                  text: notes,
                  shipmentIds: shipmentIds,
                  type: InternalNoteType.Text,
                },
              },
            }),
          ]);

        const bulkUpdateDateError = await extractGraphQLErrorIfExists(
          bulkUpdateDateResponse,
          'bulkUpdateShipmentDate',
        );
        const addInternalNotesError = await extractGraphQLErrorIfExists(
          addInternalNotesResponse,
          'addInternalNotes',
        );

        return {
          error:
            bulkUpdateDateError.error ||
            addInternalNotesError.error ||
            undefined,
        };
      }}
    />
  );
};
