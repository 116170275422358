import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import cs from 'classnames';
import React from 'react';
import { TbCheck } from 'react-icons/tb';

export type Props = {
  checked?: boolean;
  id: string;
  label?: string;
  className?: string;
  labelClassName?: string;
} & (
  | {
      disabled?: boolean;
      onChange: (c: boolean | 'indeterminate') => void;
    }
  | {
      disabled: true;
      onChange?: undefined;
    }
);

const Checkbox = ({
  checked,
  onChange,
  id,
  label,
  className,
  labelClassName,
  disabled,
}: Props) => {
  return (
    <div className="flex items-center gap-2">
      <CheckboxPrimitive.Root
        id={id}
        style={{
          width: 22,
          height: 22,
        }}
        className={cs(
          'flex items-center justify-center rounded border border-primary bg-primary text-primary',
          { 'opacity-50 cursor-not-allowed bg-secondary': disabled },
          { 'hover:bg-infoLight/20': !disabled },
          className,
        )}
        checked={checked}
        disabled={disabled}
        onCheckedChange={onChange}
      >
        <CheckboxPrimitive.Indicator>
          <TbCheck size={18} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <label htmlFor={id} className={cs('', labelClassName)}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
