import {
  getRefetchQueries,
  getRefetchRouteQueries,
} from '@components/tasks/utils';
import {
  formatFriendlyPlainDateShort,
  parsePlainDate,
} from '@packfleet/datetime';
import {
  ShipmentsOnRouteNotCollectedFragment,
  useRemoveNotCollectedShipmentsForDateMutation,
} from 'generated/graphql';
import { uniq } from 'lodash';
import Link from 'next/link';
import { useCallback } from 'react';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { Routes, linkTo } from 'utilities/routes';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentsDetail } from './utils/ShipmentsDetails';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: ShipmentsOnRouteNotCollectedFragment;
  taskId: string;
};

export const ShipmentsOnRouteNotCollected = ({ task, taskId }: Props) => {
  const [removeNotCollectedShipmentsForDate] =
    useRemoveNotCollectedShipmentsForDateMutation();

  const handleClick = useCallback(async () => {
    const vehicleIds = uniq(
      task.shipments.map((s) => s.routeActivity.vehicleId),
    );
    return extractGraphQLErrorIfExists(
      removeNotCollectedShipmentsForDate({
        variables: {
          input: {
            date: task.date,
            routeActivities: task.shipments.map((s) => ({
              activityId: s.routeActivity.routeActivityId.toString(),
              vehicleId: s.routeActivity.vehicleId.toString(),
            })),
          },
        },
        refetchQueries: [
          ...getRefetchQueries(taskId),
          ...getRefetchRouteQueries(vehicleIds),
        ],
      }),
      'removeNotCollectedShipmentsForDate',
    );
  }, [task]);

  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentsDetail shipments={task.shipments} />
      <SectionHeading title="Context" />
      <div className="px-3">
        <p className="text-sm text-secondary">Routes</p>
        <Link
          href={linkTo(Routes.routeActive, {
            date: task.date,
          })}
          className="text-link"
        >
          {formatFriendlyPlainDateShort(parsePlainDate(task.date))}
        </Link>
      </div>
      <SectionHeading title="Actions" />
      <TaskActionButton
        role="destructive"
        text="Remove all"
        onClick={handleClick}
        taskIdToCompleteOnClick={taskId}
        dialog={{
          title: 'Remove all shipments not collected',
          description:
            'You should only do this when all packs have been stowed',
        }}
      />
    </div>
  );
};
