import cs from 'classnames';
import React, { ForwardRefRenderFunction, HTMLProps, RefObject } from 'react';
import css from './ArrowBox.module.css';

type ArrowPosition = {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
};

export type Props = {
  arrowRef: RefObject<HTMLDivElement>;
  arrowPosition?: ArrowPosition;
} & HTMLProps<HTMLDivElement>;

const ArrowBox: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, arrowRef, className, arrowPosition, ...rest },
  ref,
) => {
  return (
    <div ref={ref} {...rest} className={cs(css.arrowBox, className)}>
      {children}
      <div ref={arrowRef} className={css.arrow} style={{ ...arrowPosition }} />
    </div>
  );
};

export default React.forwardRef(ArrowBox);
