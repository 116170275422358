import {
  getRefetchQueries,
  getRefetchRouteQueries,
} from '@components/tasks/utils';
import {
  ShipmentDateChangeFragment,
  useRemoveRouteActivityFromVehicleMutation,
} from 'generated/graphql';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { RouteActivity } from './utils/RouteActivity';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentDateHistory } from './utils/ShipmentDateHistory';
import { ShipmentDetails } from './utils/ShipmentDetails';
import { ShipmentNotes } from './utils/ShipmentNotes';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: ShipmentDateChangeFragment;
  taskId: string;
};

export const ShipmentDateChange = ({ task, taskId }: Props) => {
  const [removeRouteActivity] = useRemoveRouteActivityFromVehicleMutation();

  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentDetails shipmentId={task.shipmentId} />

      <SectionHeading title="Context" />
      <ShipmentDateHistory shipmentId={task.shipmentId} />
      <RouteActivity routeActivity={task.routeActivity} title="Current route" />

      <ShipmentNotes shipmentId={task.shipmentId} />

      <SectionHeading title="Actions" />

      <TaskActionButton
        text="Remove from route"
        role="primary"
        taskIdToCompleteOnClick={taskId}
        onClick={async () =>
          extractGraphQLErrorIfExists(
            removeRouteActivity({
              variables: {
                input: {
                  activityId: task.routeActivity.routeActivityId.toString(),
                  optimizedRouteVehicleId:
                    task.routeActivity.vehicleId.toString(),
                  date: task.routeActivity.date,
                },
              },
              refetchQueries: [
                ...getRefetchQueries(taskId),
                ...getRefetchRouteQueries([task.routeActivity.vehicleId]),
              ],
            }),
            'removeRouteActivityFromVehicle',
          )
        }
      />
    </div>
  );
};
