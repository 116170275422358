import { PacksLeftInVanFragment } from 'generated/graphql';
import { ShipmentsDetail } from './utils/ShipmentsDetails';

type Props = {
  task: PacksLeftInVanFragment;
  taskId: string;
};

export const PacksLeftInVan = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentsDetail
        shipments={task.packs.map((p) => ({
          ...p,
          id: p.shipmentId,
        }))}
      />
    </div>
  );
};
