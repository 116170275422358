import Dialog from '@components/dialog/Dialog';
import { Tooltip } from '@packfleet/ui';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TbPencil } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { useDebounced } from '../../hooks/useDebounced';

type ValidationFunction<Value> = (val?: Value | null) => string | undefined;

type BaseProps<Value, Editable extends boolean> = {
  className?: string;
  name: string;
  data: Value;
  icon?: ReactElement;
  children: (v: {
    value: Value;
    onChange: (val: Value) => void;
    hasValidationError?: boolean;
    editing: boolean;
    enableCopyToCliboard: boolean;
  }) => ReactNode;
  editable?: Editable;
  tooltip?: ReactNode;
};

type AdditionalPropsWhenEditable<Value> = {
  editor?: {
    title?: string;
    description?: string;
    confirmText?: string;
    loadingText?: string;
    confirmColor?: 'success' | 'info' | 'warning' | 'danger' | 'brand';
  };
  onSave: (data: Value) => Promise<{ error?: string }>;
  validate?: ValidationFunction<Value>;
};

type Props<Value, Editable extends boolean> = Editable extends true
  ? BaseProps<Value, Editable> & AdditionalPropsWhenEditable<Value>
  : BaseProps<Value, Editable> & {
      editor?: undefined;
      onSave?: undefined;
      validate?: undefined;
    };

function DetailRow<Value, Editable extends boolean>({
  name,
  className,
  data,
  icon,
  children,
  editable,
  editor,
  onSave,
  validate = () => undefined,
  tooltip,
}: Props<Value, Editable>) {
  const [value, setValue] = useState<Value>(data);
  const [editing, setEditing] = useState(false);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setValue(data);
  }, [data]);

  const [validateValue] = useDebounced(
    (value: Value) => {
      setValidationError(validate(value));
    },
    100,
    [validate],
  );

  const onConfirm = useCallback(async () => {
    let result;
    if (onSave) {
      try {
        result = await onSave(value);
      } catch (err) {
        result = { error: err?.message ?? err };
      }
    }
    if (result?.error) {
      setValue(data);
    } else {
      setEditing(false);
    }
    return result;
  }, [value, data, onSave]);

  const onCancel = useCallback(async () => {
    setEditing(false);
    setValue(data);
  }, [data]);

  const onChange = useCallback((val: Value) => {
    setValue(val);
  }, []);

  useEffect(() => {
    validateValue(value);
  }, [value]);

  const invalid = validationError != null;

  return (
    <div className="group flex items-baseline gap-6">
      <div
        className={twMerge('w-40 shrink-0 grow-0 text-secondary', className)}
      >
        {tooltip ? (
          <Tooltip title={tooltip} position="right">
            <span>{name}</span>
          </Tooltip>
        ) : (
          name
        )}
      </div>
      <div className="flex w-full flex-col gap-1 self-center">
        <div className="flex w-full items-center gap-2">
          {editable && onSave ? (
            <Dialog
              title={editor?.title || name}
              description={editor?.description ?? ''}
              onConfirm={onConfirm}
              onCancel={onCancel}
              confirmText={editor?.confirmText ?? 'Update'}
              loadingText={editor?.loadingText ?? 'Updating...'}
              confirmColor={editor?.confirmColor}
              disabled={invalid}
              trigger={
                <button
                  className="flex w-full cursor-pointer items-center gap-2 rounded-lg border border-primary p-2 leading-normal hover:border-secondary hover:bg-secondary"
                  onClick={() => setEditing(true)}
                >
                  <div className="flex grow items-center gap-2">
                    {icon}
                    {children({
                      value: data,
                      onChange,
                      editing: false,
                      enableCopyToCliboard: false,
                    })}
                  </div>
                  <TbPencil
                    size={18}
                    className="shrink-0 text-secondary group-hover:opacity-100 md:opacity-0"
                  />
                </button>
              }
            >
              {children({
                value,
                onChange,
                editing,
                hasValidationError: invalid,
                enableCopyToCliboard: false,
              })}
              {validationError ? (
                <div className="mt-2 font-medium text-danger">
                  {validationError}
                </div>
              ) : null}
            </Dialog>
          ) : (
            <div className="flex w-full items-center gap-2">
              {icon}
              {children({
                value: data,
                onChange,
                editing: false,
                enableCopyToCliboard: true,
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailRow;
