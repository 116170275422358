import cs from 'classnames';
import React, { ReactNode } from 'react';
import { ReusableComponentProps } from '../types';

type Style = 'hero' | 'title' | 'heading1' | 'heading2' | 'heading3' | 'small';

type Weight = 'light' | 'normal' | 'semiBold' | 'bold';

export type Props = ReusableComponentProps & {
  id?: string;
  level: 1 | 2 | 3 | 4;
  headingStyle?: Style;
  weight?: Weight;
  children?: ReactNode;
};

const Heading = ({
  level,
  headingStyle,
  className,
  style,
  weight = 'semiBold',
  children,
  unstyled,
  id,
}: Props) => {
  const Component = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4';
  const levelStyles: Style[] = ['heading1', 'heading2', 'heading3', 'small'];
  if (typeof headingStyle === 'undefined') {
    headingStyle = levelStyles[level - 1];
  }

  const styleClasses: { [key in Style]: string } = {
    hero: 'text-5xl',
    title: 'text-4xl',
    heading1: 'text-2xl',
    heading2: 'text-xl',
    heading3: 'text-lg',
    small: 'text-base',
  };

  const fontClasses: { [key in Weight]: string } = {
    light: 'font-light',
    normal: 'font-normal',
    semiBold: 'font-semibold',
    bold: 'font-bold',
  };

  return (
    <Component
      id={id}
      className={cs(
        !unstyled ? fontClasses[weight] : undefined,
        !unstyled ? styleClasses[headingStyle] : undefined,
        className,
      )}
      style={style}
    >
      {children}
    </Component>
  );
};

export default Heading;
