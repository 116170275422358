import DetailLocation from '@/components/details/DetailLocation';
import classNames from 'classnames';
import { diffChars, diffWords } from 'diff';
import {
  Address,
  AddressReviewLineIssue,
  CleanseApiMatchLine,
  CleanseApiMatchType,
  CleanseApiSuggestedAddress,
} from '../../../../../generated/graphql';

type ValidationResult = {
  addressLineIssues: Array<AddressReviewLineIssue>;
  suggestedAddress?: Omit<
    CleanseApiSuggestedAddress,
    'locality' | 'possibleMatchCount' | 'premise' | 'street'
  > | null;
};

export type Props = {
  address: Address;
  validationResult: ValidationResult;
};

export const AddressIssues = ({ address, validationResult }: Props) => {
  const userProvidedAddress = addressToString(address);
  const suggestedAddress = validationResult.suggestedAddress
    ? addressToString(validationResult.suggestedAddress)
    : undefined;
  /**
    The generateDifference checks for differences in lines of text. We only want
    it to run if the backend says there is an issue. Example scenario: will see
    the missing whitespace in postcode scenario as an issue, but our backend
    says it's not. Here we want to only do the postcode differencing if
    the backend says there is an issue.
   */
  let isPostcodeIssue = false;
  let isStreetOrPremiseIssue = false;
  let isPoBox = false;
  validationResult.addressLineIssues.forEach((issue) => {
    if (
      issue.line === CleanseApiMatchLine.Premise ||
      issue.line === CleanseApiMatchLine.Street
    ) {
      isStreetOrPremiseIssue = true;
    }
    if (issue.issue === CleanseApiMatchType.IsPoBox) {
      isPoBox = true;
    } else if (issue.line === CleanseApiMatchLine.Postcode) {
      isPostcodeIssue = true;
    }
  });

  return (
    <>
      <div>
        <p className="text-info">
          <strong>Issues Detected</strong>
        </p>
        <div className="my-3 p-3 bg-warningLight rounded">
          <ul>
            {validationResult.addressLineIssues.map((issue, index) => (
              <li key={index}>
                <strong>{`⚠️ ${mapAddressLineToReadable(issue.line)} `}</strong>
                {`might be ${mapAddressIssueToReadable(issue.issue)}`}
              </li>
            ))}
          </ul>
        </div>
        {isStreetOrPremiseIssue && suggestedAddress ? (
          <div>
            {generateDifference(userProvidedAddress, suggestedAddress, 'words')}
          </div>
        ) : null}
        {isPoBox ? (
          <div>
            <p>
              <span>
                This postcode <strong>({address.postCode})</strong> points to a
                PO box and may not be deliverable.
              </span>{' '}
              <br /> Please double-check and change it if needed.
            </p>
          </div>
        ) : null}
        {isPostcodeIssue && validationResult.suggestedAddress ? (
          <div>
            {generateDifference(
              address.postCode,
              validationResult.suggestedAddress.postCode,
              'chars',
            )}
          </div>
        ) : null}
        {address.geocodedAddress && (
          <div className="mt-3 whitespace-pre-wrap text-sm text-secondary border border-secondary rounded p-2">
            <p>
              <strong>Geocoding</strong>
            </p>
            <p>{address.geocodedAddress.streetAddress}</p>
          </div>
        )}
      </div>
      {validationResult.suggestedAddress ? (
        <div className="mt-4">
          <p>
            <strong className="text-success">Suggested address </strong>
            <span className="italic text-secondary">
              {`(${Math.round(
                validationResult.suggestedAddress.confidence * 100,
              )}% confidence)`}
            </span>
          </p>
          <DetailLocation
            value={{
              address: validationResult.suggestedAddress,
              name: validationResult.suggestedAddress.organizationName,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

function mapAddressIssueToReadable(issue: CleanseApiMatchType) {
  switch (issue) {
    case CleanseApiMatchType.Incorrect:
      return 'incorrect';
    case CleanseApiMatchType.Missing:
      return 'missing';
    case CleanseApiMatchType.IsPoBox:
      return 'a PO box';
    case CleanseApiMatchType.Partial:
      return 'partially correct';
    default:
      return 'ok';
  }
}
function mapAddressLineToReadable(line: CleanseApiMatchLine) {
  switch (line) {
    case CleanseApiMatchLine.OrganizationName:
      return 'organization name';
    default:
      return line.toString();
  }
}
function generateDifference(
  addressComponentA: string,
  addressComponentB: string,
  type: 'words' | 'chars',
) {
  if (addressComponentA.toLowerCase() === addressComponentB.toLowerCase()) {
    return null;
  }

  const differences = (type === 'chars' ? diffChars : diffWords)(
    addressComponentA,
    addressComponentB,
    { ignoreCase: true },
  );

  return (
    <div className="overflow-x-auto w-full">
      <table className="whitespace-pre text-sm mx-2">
        <tr>
          <td className="text-right"></td>
          {differences.map((d, index) =>
            !d.added ? (
              <td
                key={index}
                className={classNames('text-sm p-0', {
                  'text-danger font-bold': d.removed,
                  'text-secondary font-thin': !d.removed,
                })}
              >
                {d.value}
              </td>
            ) : // to ensure both rows render the same number of elements if the previous group was removed then we don't want to render a cell here
            !differences[index - 1]?.removed ? (
              <td key={index} />
            ) : null,
          )}
        </tr>
        <tr>
          <td className="text-right">→ </td>
          {differences.map((d, index) =>
            !d.removed ? (
              <td
                key={index}
                className={classNames('text-sm p-0', {
                  'text-brand font-bold': d.added,
                  'text-secondary font-thin': !d.added,
                })}
              >
                {d.value}
              </td>
            ) : // to ensure both rows render the same number of elements if the next group is added then we don't want to render a cell here
            !differences[index + 1]?.added ? (
              <td key={index} />
            ) : null,
          )}
        </tr>
      </table>
    </div>
  );
}

function addressToString(addr: {
  line1: string | null;
  line2?: string | null;
  line3?: string | null;
}) {
  return [addr.line1, addr.line2, addr.line3]
    .filter((a) => Boolean(a))
    .join(', ');
}
