import React, { useEffect, useRef, useState } from 'react';
import PopoverMenu, { MenuItem } from './PopoverMenu';

export type Props = {
  button: React.ReactElement;
  menuItems: MenuItem[];
  menuMinWidth?: number;
  activeHref?: string;
};

const ButtonWithMenu = ({
  button,
  menuItems,
  menuMinWidth,
  activeHref,
}: Props) => {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuInit, setMenuInit] = useState(false);
  useEffect(() => {
    setMenuInit(true);
  }, []);
  return (
    <>
      <div ref={menuRef}>
        {React.cloneElement(button, {
          onClick: () => setShowMenu(!showMenu),
        })}
      </div>
      {menuInit ? (
        <PopoverMenu
          show={showMenu}
          activeHref={activeHref}
          attachToRef={menuRef}
          onClose={() => setShowMenu(false)}
          menuItems={menuItems}
          minWidth={menuMinWidth}
        />
      ) : null}
    </>
  );
};

export default ButtonWithMenu;
