import { CollectionOnMoreThanOneRouteFragment } from 'generated/graphql';
import { CollectionDetails } from './utils/CollectionDetails';
import { RouteActivity } from './utils/RouteActivity';

type Props = {
  task: CollectionOnMoreThanOneRouteFragment;
  taskId: string;
};

export const CollectionOnMoreThanOneRoute = ({ task }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <CollectionDetails
        collectionId={task.collectionId}
        organizationId={task.organizationId}
      />

      {task.routeActivities.map((routeActivity, index) => (
        <RouteActivity
          key={routeActivity.routeActivityId}
          routeActivity={routeActivity}
          title={`Route ${index + 1}`}
        />
      ))}
    </div>
  );
};
