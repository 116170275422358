import cs from 'classnames';
import React from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

export type Props = {
  count: number;
  className?: string;
} & SkeletonProps;

const SkeletonList = ({ count, className, ...props }: Props) => {
  return (
    <div className={cs('flex flex-col gap-4', className)}>
      {new Array(count).fill(0).map((_, i) => (
        <Skeleton key={i} {...props} />
      ))}
    </div>
  );
};

export default SkeletonList;
