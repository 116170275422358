export enum PanelLevel {
  TASK_SIDEBAR,
  TASK_DETAILS,

  // Routes
  VEHICLE_LIST,
  ACTIVITY_LIST,
  ACTIVITY_DETAIL,

  // Routing Home
  ROUTES_LIST,
  NEW_ROUTE,
  NEW_ROUTE_STOP_SELECTION,
  ROUTE_DETAIL,
  ISSUES,
  SHIFT_DETAIL,
}
