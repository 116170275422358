import { IconButton } from '@packfleet/ui';
import { CollectionsNotOnRouteFragment } from 'generated/graphql';
import groupBy from 'lodash.groupby';
import Link from 'next/link';
import { useId, useMemo, useState } from 'react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { formatAddress } from 'utilities/address';
import { Routes, linkTo } from 'utilities/routes';
import { pluralize } from 'utilities/string';
import { formatStartEndTime } from 'utilities/time';

type Props = {
  collections: CollectionsNotOnRouteFragment['collections'];
};
export const CollectionsDetails = ({ collections }: Props) => {
  const byOrg = useMemo(
    () => groupBy(collections, (c) => c.organization.id),
    [collections],
  );
  const orgIds = Object.keys(byOrg);
  const [expanded, setExpanded] = useState(false);
  const expandedSectionId = useId();

  return (
    <div className="px-3">
      <div className="border border-secondary rounded p-4">
        <div className="flex items-center justify-between">
          <div>
            <p>
              {collections.length}{' '}
              {pluralize(collections.length, 'Collection', 'Collections')}
            </p>
            <ul>
              {orgIds.map((orgId) => (
                <li key={orgId} className="text-sm">
                  <Link
                    href={linkTo(Routes.merchant, { id: orgId })}
                    className="text-[#3f81bd]"
                  >
                    {byOrg[orgId][0].organization.name}
                  </Link>{' '}
                  ({byOrg[orgId].length})
                </li>
              ))}
            </ul>
          </div>
          <div>
            <IconButton
              icon={expanded ? TbChevronUp : TbChevronDown}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-controls={expandedSectionId}
              aria-label="Full collections list"
            />
          </div>
        </div>
        <div id={expandedSectionId}>
          {expanded
            ? orgIds.map((orgId) => (
                <div key={orgId}>
                  <p className="mt-4">{byOrg[orgId][0].organization.name}</p>
                  <ul>
                    {byOrg[orgId].map((c) => (
                      <li key={c.id}>
                        <Link
                          href={linkTo(Routes.merchantCollection, {
                            id: c.organization.id,
                            collectionId: c.id,
                          })}
                          className="text-[#3f81bd] text-sm"
                        >
                          {formatStartEndTime(c.startTime, c.endTime)}
                          {' - '}
                          {formatAddress(c.address)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
