import { AddressFeedbackFromDriverFragment } from 'generated/graphql';
import { BsPin } from 'react-icons/bs';
import { truthy } from 'utilities/truthy';
import { MapInfo } from '../../useTasksContext';

export function getMapMarkers(
  task: AddressFeedbackFromDriverFragment,
): MapInfo {
  return {
    markers: [getMarker(task)].filter(truthy),
  };
}
function getMarker(
  task: AddressFeedbackFromDriverFragment,
): MapInfo['markers'][number] | null {
  if (!task.location) {
    return null;
  }

  return {
    id: task.routeActivity.routeActivityId.toString(),
    routeDate: task.routeActivity.date,
    title: 'Driver submitted location',
    icon: BsPin,
    backgroundColor: 'white',
    borderColor: 'info',
    location: {
      lat: task.location.latitude,
      lng: task.location.longitude,
    },
  };
}
