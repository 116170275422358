import classNames from 'classnames';
import React from 'react';
import { DeliveryDateReason } from '../../generated/graphql';

export type Props = {
  dateReason?: DeliveryDateReason | null;
};

const DATE_REASONS: Record<DeliveryDateReason, string> = {
  [DeliveryDateReason.RecipientPreference]: 'Date requested by recipient',
  [DeliveryDateReason.AutoUpdatedOnCollection]: 'Auto-updated on collection',
  [DeliveryDateReason.AutoUpdatedOnOutForDelivery]:
    'Auto-updated on out-for-delivery',
  [DeliveryDateReason.AutoUpdatedOnFailedDelivery]:
    'Auto-updated on failed delivery',
  [DeliveryDateReason.AutoUpdatedOnLabelGeneration]:
    'Auto-updated on label generation',
  [DeliveryDateReason.AutoUpdatedDueToBusinessAddress]:
    'Auto-updated due to business address',
  [DeliveryDateReason.AutoUpdatedOnDelivery]: 'Auto-updated on delivery',
  [DeliveryDateReason.StaffOverride]: 'Date overridden by us',
  [DeliveryDateReason.SuperEcoDeliveryOptIn]: 'Super-eco delivery opt-in',
  [DeliveryDateReason.UpdatedViaApi]: 'Updated via API',
};

export const getDateReason = (reason: DeliveryDateReason) =>
  DATE_REASONS[reason] ?? reason;

export const DeliveryDateReasonDisplay = ({ dateReason }: Props) => {
  if (!dateReason) {
    return null;
  }

  const className =
    dateReason === DeliveryDateReason.RecipientPreference
      ? 'text-warning'
      : dateReason === DeliveryDateReason.StaffOverride
        ? 'text-info'
        : dateReason === DeliveryDateReason.AutoUpdatedOnCollection ||
            dateReason === DeliveryDateReason.AutoUpdatedOnOutForDelivery
          ? 'text-secondary'
          : null;

  return (
    <div className={classNames('text-sm font-medium', className)}>
      {getDateReason(dateReason)}
    </div>
  );
};
