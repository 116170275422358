import {
  TZ_EUROPE_LONDON,
  formatInstantTime,
  parseInstant,
} from '@packfleet/datetime';
import { IconButton } from '@packfleet/ui';
import groupBy from 'lodash.groupby';
import Link from 'next/link';
import { useId, useMemo, useState } from 'react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';

import { Routes, linkTo } from 'utilities/routes';
import { pluralize } from 'utilities/string';
import { formatStartEndTime } from 'utilities/time';

type Props = {
  shipments: {
    organizationId: string;
    organizationName: string;
    id: string;
    trackingPhrase: string;
    packNumber?: number;
    totalPacks?: number;
    timeWindow?:
      | {
          startTime?: string | null | undefined;
          endTime?: string | null | undefined;
        }
      | null
      | undefined;
    perishable: boolean;
  }[];
};
export const ShipmentsDetail = ({ shipments }: Props) => {
  const byOrg = useMemo(
    () => groupBy(shipments, (s) => s.organizationId),
    [shipments],
  );
  const orgIds = Object.keys(byOrg);
  const [expanded, setExpanded] = useState(false);
  const expandedSectionId = useId();

  return (
    <div className="px-3">
      <div className="border border-secondary rounded p-4">
        <div className="flex items-center justify-between">
          <div>
            <p>
              {shipments.length}{' '}
              {pluralize(shipments.length, 'Shipment', 'Shipments')}
            </p>
            <ul>
              {orgIds.map((orgId) => (
                <li key={orgId} className="text-sm">
                  <Link
                    href={linkTo(Routes.merchant, { id: orgId })}
                    className="text-[#3f81bd]"
                  >
                    {byOrg[orgId][0].organizationName}
                  </Link>{' '}
                  ({byOrg[orgId].length})
                </li>
              ))}
            </ul>
          </div>
          <div>
            <IconButton
              icon={expanded ? TbChevronUp : TbChevronDown}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-controls={expandedSectionId}
              aria-label="Full shipments list"
            />
          </div>
        </div>
        <div id={expandedSectionId}>
          {expanded
            ? orgIds.map((orgId) => (
                <div key={orgId}>
                  <p className="mt-2">{byOrg[orgId][0].organizationName}</p>
                  <ul>
                    {byOrg[orgId].map((s) => (
                      <li key={s.id}>
                        <Link
                          href={linkTo(Routes.shipment, { id: s.id })}
                          className="text-[#3f81bd] text-sm"
                        >
                          {s.trackingPhrase}
                        </Link>
                        {s.packNumber && s.totalPacks && s.totalPacks > 1 ? (
                          <span className="pl-1 opacity-70 text-xs">
                            Pack {s.packNumber} of {s.totalPacks}
                          </span>
                        ) : null}
                        {s.timeWindow ? (
                          <>
                            {' '}
                            (
                            {formatStartEndTime(
                              s.timeWindow.startTime
                                ? formatInstantTime(
                                    parseInstant(s.timeWindow.startTime),
                                    TZ_EUROPE_LONDON,
                                  )
                                : null,
                              s.timeWindow.endTime
                                ? formatInstantTime(
                                    parseInstant(s.timeWindow.endTime),
                                    TZ_EUROPE_LONDON,
                                  )
                                : null,
                            )}
                            )
                          </>
                        ) : null}
                        {s.perishable ? (
                          <span className="select-none ml-1">❄️</span>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
